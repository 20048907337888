import axios from "axios";
const apiDomain = process.env.REACT_APP_API_DOMAIN;

export const addEmployeeDetails = async(payload:any)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/employees`
            const response = await axios.post(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while post Employees Details", error);
    }
}

export const editEmployeeDetails = async(payload:any,employeeid:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/employees/${employeeid}`
            const response = await axios.put(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Edit Employees Details", error);
    }
}

export const getEmployeeDetails = async()=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/employees`
            const response = await axios.get(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while get All Employees Details", error);
    }
}

export const deleteEmployeeDetails = async(employeeid:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/employees/${employeeid}`
            const response = await axios.delete(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Delete Employees Details", error);
    }
}

