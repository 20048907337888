import { FC, ReactNode, useRef } from "react";
import "./RightDrawer.css";

interface RightDrawerProps {
  open: boolean;
  handleOpen: () => void;
  children: ReactNode;
  wideView?:boolean;
}

const RightDrawer: FC<RightDrawerProps> = ({ open, handleOpen, children ,wideView = false}) => {
  const drawerRef = useRef<any>(null);

  return (
    <div ref={drawerRef}
    //  className={`drawer ${open ? "open" : ""}`}
     className={`fixed top-0 right-0 h-full bg-white overflow-x-hidden transition-all duration-300 ease-in-out z-10 shadow-[rgba(0,0,0,0.1)_-2px_0px_5px] 
      ${!wideView && open ? "sm:w-[40vw] lg:w-[30vw] xl:w-[25vw] md:w-[50vw]" : "w-0"}
      ${wideView && open ? "sm:w-[60vw] lg:w-[83vw] md:w-[70vw]" : "w-0"}
      `}
     >
      <div className="p-6">
        <div
          className="flex justify-end cursor-pointer"
          onClick={handleOpen}
          style={{ fontSize: "24px" }}
        >
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

export default RightDrawer;
