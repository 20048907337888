import axios from "axios";
export async function sendOTP(email: string) {
  try {

    console.log('window.location.origin', window.location.origin)
    const url = `${process.env.REACT_APP_API_DOMAIN}/api/v2/auth/passwordless/magicCodes`
    console.log('url', url)
    const response = await axios.post(
      url,
      {
        email: email
      }
    );

    console.log('send magic code response', response)
    localStorage.setItem(
      "passwordlessMagicCodeId",
      response.data.passwordlessMagicCodeId
    );
    return response;
  } catch (err) {
    console.log("Error while sending magic code", err);
    window.alert("Server error. Could not send magic code!");
  }
}
