import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import TableHeader from "../TableHeader/TableHeader";
import NoUsersScreen from "../NoUsersScreen";
import TableBody from "../TableBody/BasicDetails";

const BasicDetails = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [statusValue, setStatusValue] = useState<any>([]);
  const [roleValue, setRoleValue] = useState<any>([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const toggleAddUserModal = () => setOpenAddUserModal((old) => !old);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if(userContext.workspaces?.length === 0){
      userContext.fetchWorkspaces();
    }
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [userContext.workspaces]);

  return (
    <>
      <TableHeader
      addData={toggleAddUserModal}
      fetchData={userContext.fetchWorkspaces}
      usersCount={userContext.workspaces?.length}
      buttonText={'Add Workspace'}
      subtittle={`Total ${userContext.workspaces?.length} Workspaces`}
      tittle="Workspace"
      isCreate={false}
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {userContext.workspaces?.length > 0 && tableHeight ? (
          <TableBody
            tableHeight={tableHeight}
            searchText={searchText}
            users={userContext.workspaces}
            statusValue={statusValue}
            roleValue={roleValue}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={()=>{}}
            message={'No Workspace Added'}
            isForUserGroup={false}
            isEdit={false}
            buttonText={'Add Workspace'}
          />
        )}
      </div>
    </>
  );
};
export default BasicDetails;
