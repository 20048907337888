import './App.css';
import Dashboard from './components/Content/Dashboard/Dashboard';
function App() {
  return (
      <div className="wrapper">
        <div>
          {/* <NavbarWithCTAButton /> */}
          <Dashboard />
        </div>
      </div>
  );
}

export default App;