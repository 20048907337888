import {  Dropdown } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRowId } from "../utils/helper";
import {defaultColDef} from "../utils/constants";
import { UserContext } from "../../../../context/UserContext";
import { HiDotsVertical } from "react-icons/hi";
import AddEditWorkspaceModal from "../Modals/BasicDetails/AddEditWorkspaceModal";

interface UsersListProps {
  tableHeight: number;
  searchText: string;
  users: any[];
  statusValue: any;
  roleValue: any;
}

const BasicDetails: FC<UsersListProps> = ({
  tableHeight,
  searchText,
  statusValue,
  users,
  roleValue,
}) => {
  const tableRef = useRef<AgGridReact>(null);
  const userContext = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const toggleEditUserModal = () => setOpenEditUserModal((old) => !old);

  const onClickEditUserIcon = (user: any) => {
    setSelectedUser(user);
    toggleEditUserModal();
  };

  const onCheckboxChange = () => {
    console.log("selected");
  };

  useEffect(() => {
    tableRef?.current?.api?.redrawRows({ rowNodes: userContext.users });
  }, [userContext.users, roleValue, statusValue]);

  const columnDefs: any = [
    {
      field: "name",
      headerName: "Workspace Name",
      cellRenderer: (params: any) => (
        <div className="flex items-center pt-2">
          <div className="flex flex-row h-full text-sm font-semibold items-center">
            <div style={{ maxWidth: 220, overflow: "hidden" }}>
              {params?.data?.name}
            </div>
          </div>
          <div className="ml-auto">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <span>
                  <HiDotsVertical />
                </span>
              }
            >
                <Dropdown.Item
                  onClick={() => onClickEditUserIcon(params?.data)}
                >
                  Edit Workspace Info
                </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
      cellStyle: { border: "unset", position: "static" },
      minWidth: 350,
      unSortIcon: true,
      pinned: true,
    },
    {
      field: "subdomain",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.subdomain}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "Icon",
      headerName: "Icon",
      cellRenderer: (params: any) => {
        console.log("Image url >>",`${process.env.REACT_APP_LINODE_ENDPOINT}${process.env.REACT_APP_LINODE_DEFAULT_BUCKET}/${params.data.iconUrl}`);  
        return (
            <div className="flex p-2 flex-col items-start w-5/6 h-2/6">
              <label
                className="flex flex-col items-center justify-center h-8 border-2 border-gray-300 border-dashed rounded-[5px] cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <img
                  alt="image"
                  src={`${process.env.REACT_APP_LINODE_ENDPOINT}${process.env.REACT_APP_LINODE_DEFAULT_BUCKET}/${params.data.iconUrl}`}
                  className="object-cover h-full w-full"
                  onError={(e:any) => {
                    e.target.onerror = null; 
                    e.target.src = 'https://play.teleporthq.io/static/svg/default-img.svg';
                  }}
                />
              </label>
            </div>
        );
      },
      minWidth: 80,
      maxWidth: 120,
      // cellStyle: { border: "unset" },
      unSortIcon: true,
    }
  ];

  const noRowsComponent = () => {
    return (
      <div className="ag-overlay-no-rows-wraper">
        <div className="text-2xl text-gray-400">Nothing to display!</div>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
      <AgGridReact
        ref={tableRef}
        rowData={users}
        getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onSelectionChanged={onCheckboxChange}
        noRowsOverlayComponent={noRowsComponent}
        gridOptions={{
          rowSelection: "multiple",
          suppressRowClickSelection: true,
          suppressContextMenu: true,
        }}
        quickFilterText={searchText}
        getRowStyle={() => ({
          background: "transparent",
          transform: "none",
          position: "relative",
        })}
        rowHeight={50}
        pagination={true}
        paginationPageSizeSelector={[10, 20, 50, 100]}
        paginationPageSize={10}
      />
      <AddEditWorkspaceModal
        open={openEditUserModal}
        toggleModal={toggleEditUserModal}
        selectedUser={selectedUser}
        existingUserEmails={[]}
      />
    </div>
  );
};

export default BasicDetails;
