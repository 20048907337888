import { createContext, useState, ReactNode } from "react";
import { LocationContext as LocationContextInterface, Location } from "../interfaces";
import { getAllLocations } from "../Utilities/AdminConsole/workspace.location";

const defaultProvider: LocationContextInterface = {
	locations: [],
	fetchLocations: () => {},
	setLocations: () => {},
	selectedLocation: null,
	setSelectedLocation: () => {},
};

const LocationContext = createContext<LocationContextInterface>(defaultProvider);


function LocationProvider({ children }: any) {
	const [locations, setLocations] = useState<any>([]);
	const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

	const fetchLocations = async () => {
		try {
			const response = await getAllLocations();
			console.log("🚀 ~ fetchLocations ~ locations:", response);
			setLocations(response);
		} catch (error) {
			console.log("🚀 ~ fetchLocations ~ error:", error);
		}
	};

	const values = {
		locations,
		fetchLocations,
		setLocations,
		selectedLocation,
		setSelectedLocation,
	};

	return (
		<LocationContext.Provider value={values}>
			{children}
		</LocationContext.Provider>
	);
}

export { LocationContext, LocationProvider };