import  { useEffect,  useRef } from "react";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import "./map.css";
import {  UseDrawingManagerProps } from "../types";

declare global {
  interface Window {
    googleAutocomplete: google.maps.places.Autocomplete;
  }
}

export function useDrawingManager(
  initialValue: google.maps.drawing.DrawingManager | null = null,
  props: UseDrawingManagerProps
) {
  const { setFieldValue, geoRange, isEdit } = props;
  const map = useMap();
  const drawing = useMapsLibrary("drawing");
  const geometry = useMapsLibrary("geometry"); // Add geometry library
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager | null>(null);
  const currentShapeRef = useRef<google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | null>(null);
  const MAX_RADIUS = 150;
  const controlsInitializedRef = useRef(false);
const saveShape = (shape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle, type: string) => {
    const shapeData: any = { type };

    if (type === google.maps.drawing.OverlayType.CIRCLE && shape instanceof google.maps.Circle) {
      shapeData.options = {
        center: shape.getCenter()?.toJSON(),
        radius: shape.getRadius(),
      };
    } else if (type === google.maps.drawing.OverlayType.POLYGON && shape instanceof google.maps.Polygon) {
      shapeData.options = {
        path: shape.getPath().getArray().map((latLng) => latLng.toJSON()),
      };
    } else if (type === google.maps.drawing.OverlayType.RECTANGLE && shape instanceof google.maps.Rectangle) {
      const bounds = shape.getBounds();
      shapeData.options = {
        north: bounds?.getNorthEast().lat(),
        east: bounds?.getNorthEast().lng(),
        south: bounds?.getSouthWest().lat(),
        west: bounds?.getSouthWest().lng(),
      };
    }

    setFieldValue("geoRange", shapeData);
  };

  const clearCurrentShape = () => {
    if (currentShapeRef.current) {
      currentShapeRef.current.setMap(null);
      currentShapeRef.current = null;
    }
  };

  const createShapeFromGeoJSON = (geoJson: any) => {
    if (!map) return;
    clearCurrentShape();

    let shape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle | null = null;
    let shapeType: string = "";

    switch (geoJson.type.toLowerCase()) {
      case "circle":
        shape = new google.maps.Circle({
          center: geoJson.options.center,
          radius: Math.min(geoJson.options.radius, MAX_RADIUS),
          map,
          editable: true,
          draggable: true
        });
        shapeType = google.maps.drawing.OverlayType.CIRCLE;
        if (shape.getCenter()) {
          map.setCenter(shape.getCenter()!);
          map.setZoom(18);
        }
        break;

      case "polygon":
        shape = new google.maps.Polygon({
          paths: geoJson.options.path,
          map,
          editable: true,
          draggable: true
        });
        shapeType = google.maps.drawing.OverlayType.POLYGON;
        const polygonBounds = new google.maps.LatLngBounds();
        geoJson.options.path.forEach((point: any) => {
          polygonBounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
        map.fitBounds(polygonBounds);
        break;

      case "rectangle":
        const bounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(geoJson.options.south, geoJson.options.west),
          new google.maps.LatLng(geoJson.options.north, geoJson.options.east)
        );
        shape = new google.maps.Rectangle({
          bounds,
          map,
          editable: true,
          draggable: true
        });
        shapeType = google.maps.drawing.OverlayType.RECTANGLE;
        map.fitBounds(bounds);
        break;
    }

    if (shape) {
      currentShapeRef.current = shape;
      addListenersToShape(shape, shapeType);
    }
  };


  const addListenersToShape = (shape: google.maps.Circle | google.maps.Polygon | google.maps.Rectangle,type: string) => {
    if (type === google.maps.drawing.OverlayType.CIRCLE && shape instanceof google.maps.Circle) {
  let initialRadius = shape.getRadius();
  
  if (initialRadius > MAX_RADIUS) {
    alert(`The maximum allowed radius is ${MAX_RADIUS} meters.`);
    initialRadius = MAX_RADIUS;
  }
  shape.setRadius(initialRadius);

  google.maps.event.addListener(shape, "center_changed", () => {
    saveShape(shape, type);
  });

  google.maps.event.addListener(shape, "radius_changed", () => {
    const currentRadius = shape.getRadius();
    if (currentRadius > MAX_RADIUS) {
      shape.setRadius(MAX_RADIUS);
      alert(`The maximum allowed radius is ${MAX_RADIUS} meters.`);
    }
    saveShape(shape, type);
    });
    }else if (type === google.maps.drawing.OverlayType.POLYGON && shape instanceof google.maps.Polygon) {
      const MAX_AREA_IN_SQUARE_FEET = 300; // Maximum allowed area
      const TOLERANCE = 1; // Tolerance for area calculation
    
      const calculatePolygonArea = () => {
        if (!google.maps.geometry || !google.maps.geometry.spherical) {
          console.error("Google Maps Geometry library is not loaded.");
          return 0; // Return 0 to avoid breaking the app
        }
    
        const path = shape.getPath();
        const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);
        return areaInSquareMeters * 10.7639; // Convert to square feet
      };
    
      const scalePolygonToMaxArea = (desiredAreaInSquareFeet: number) => {
        if (!google.maps.geometry || !google.maps.geometry.spherical) {
          console.error("Google Maps Geometry library is not loaded.");
          return;
        }
    
        const currentArea = calculatePolygonArea();
        if (currentArea <= desiredAreaInSquareFeet) return;
    
        const scaleFactor = Math.sqrt(desiredAreaInSquareFeet / currentArea);
    
        const path = shape.getPath();
        const bounds = new google.maps.LatLngBounds();
    
        // Calculate the centroid of the polygon
        path.forEach((latLng) => bounds.extend(latLng));
        const center = bounds.getCenter();
    
        // Scale each vertex relative to the centroid
        const newPath: google.maps.LatLng[] = [];
        path.forEach((latLng) => {
          const newLat = center.lat() + (latLng.lat() - center.lat()) * scaleFactor;
          const newLng = center.lng() + (latLng.lng() - center.lng()) * scaleFactor;
          newPath.push(new google.maps.LatLng(newLat, newLng));
        });
    
        // Update the polygon's path
        shape.setPath(newPath);
      };
    
      const enforceAreaConstraints = () => {
        if (!google.maps.geometry || !google.maps.geometry.spherical) {
          console.error("Google Maps Geometry library not loaded.");
          return;
        }
    
        if (shape.getPath().getLength() < 3) {
          alert("A polygon must have at least 3 vertices.");
          return;
        }
    
        const areaInSquareFeet = calculatePolygonArea();
    
        if (areaInSquareFeet > MAX_AREA_IN_SQUARE_FEET + TOLERANCE) {
          alert(`The selected polygon area exceeds the maximum allowed range of ${MAX_AREA_IN_SQUARE_FEET} square feet. Adjusting to the maximum area.`);
    
          // Adjust the polygon to the maximum area
          scalePolygonToMaxArea(MAX_AREA_IN_SQUARE_FEET);
        }
    
        saveShape(shape, type);
      };
    
      const handlePathChange = () => {
        setTimeout(() => {
          enforceAreaConstraints();
        }, 0);
      };
    
      const attachPolygonListeners = () => {
        const path = shape.getPath();
        google.maps.event.addListener(path, "set_at", handlePathChange);
        google.maps.event.addListener(path, "insert_at", handlePathChange);
        google.maps.event.addListener(path, "remove_at", handlePathChange);
        google.maps.event.addListener(shape, "dragend", handlePathChange);
      };
    
      // Initial setup
      attachPolygonListeners();
      enforceAreaConstraints();
    }
    
    else if (type === google.maps.drawing.OverlayType.RECTANGLE && shape instanceof google.maps.Rectangle) {
      const MAX_AREA_SQUARE_FEET = 300; // Maximum allowed area
    
      const setRectangleToFixedArea = (desiredAreaInSquareFeet: number) => {
        const desiredAreaInSquareMeters = desiredAreaInSquareFeet / 10.7639;
        const sideLengthInMeters = Math.sqrt(desiredAreaInSquareMeters);
    
        const bounds = shape.getBounds();
        if (!bounds) {
          console.error("Bounds are not available.");
          return;
        }
    
        const center = bounds.getCenter();
        const centerLat = center.lat();
        const centerLng = center.lng();
    
        const latOffset = sideLengthInMeters / 111320;
        const lngOffset = sideLengthInMeters / (111320 * Math.cos((centerLat * Math.PI) / 180));
    
        const newBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(centerLat - latOffset / 2, centerLng - lngOffset / 2),
          new google.maps.LatLng(centerLat + latOffset / 2, centerLng + lngOffset / 2)
        );
    
        google.maps.event.clearListeners(shape, "bounds_changed");
        shape.setBounds(newBounds);
        shape.setEditable(false);
        setTimeout(() => {
          shape.setEditable(true);
          google.maps.event.addListener(shape, "bounds_changed", handleBoundsChanged);
        }, 100);
      };
    
      const calculateRectangleArea = (bounds: google.maps.LatLngBounds) => {
        if (!google.maps.geometry || !google.maps.geometry.spherical) {
          console.error("Google Maps Geometry library is not loaded.");
          return 0;
        }
    
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const path = [ne,new google.maps.LatLng(ne.lat(), sw.lng()),sw,new google.maps.LatLng(sw.lat(), ne.lng()),];
        const areaInSquareMeters = google.maps.geometry.spherical.computeArea(path);
        return areaInSquareMeters * 10.7639; // Convert to square feet
      };
    
      const handleBoundsChanged = () => {
        const bounds = shape.getBounds();
        if (!bounds) return;
    
        const areaInSquareFeet = calculateRectangleArea(bounds);
        const tolerance = 1;
    
        if (areaInSquareFeet > MAX_AREA_SQUARE_FEET + tolerance) {
          // Show alert box
          alert(`The rectangle area (${areaInSquareFeet.toFixed(2)} square feet) exceeds the maximum allowed area of ${MAX_AREA_SQUARE_FEET} square feet. It will be resized automatically.`);
    
          // Adjust the rectangle to the maximum area
          setRectangleToFixedArea(MAX_AREA_SQUARE_FEET);
        } else {
          saveShape(shape, type);
        }
      };
    
      google.maps.event.addListener(shape, "bounds_changed", handleBoundsChanged);
    
      const bounds = shape.getBounds();
      if (bounds) {
        handleBoundsChanged();
      }
    }
  };

  useEffect(() => {
    if (!map || !drawing) return;

    if (geoRange && typeof geoRange === 'object' && isEdit) {
      createShapeFromGeoJSON(geoRange);
    }
    
    const initializeDrawingManager = () => {
      if (controlsInitializedRef.current || drawingManagerRef.current) return;
      const newDrawingManager = new drawing.DrawingManager({
        map,
        drawingControl: !currentShapeRef.current,
        drawingControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        circleOptions: {
          editable: true,
          draggable: true,
          radius: 150,
        },
        rectangleOptions: {
          editable: true,
          draggable: true,
        },
      });

      drawingManagerRef.current = newDrawingManager;
      
      google.maps.event.addListener(newDrawingManager, "overlaycomplete", (event: google.maps.drawing.OverlayCompleteEvent) => {
        if (!(event.overlay instanceof google.maps.Circle) && 
            !(event.overlay instanceof google.maps.Polygon) && 
            !(event.overlay instanceof google.maps.Rectangle)) return;

        clearCurrentShape();
        const overlay = event.overlay;
        const type = event.type;

        if (overlay) {
          currentShapeRef.current = overlay;
          overlay.setMap(map);
          addListenersToShape(overlay, type);
          saveShape(overlay, type);
          newDrawingManager.setDrawingMode(null);
          newDrawingManager.setOptions({
            drawingControl: false
          });
        }
      });
      
      controlsInitializedRef.current = true;
    };

    initializeDrawingManager();

    const input = document.getElementById("place-input") as HTMLInputElement;
    if (input) {
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.setFields(["place_id","geometry","name","formatted_address","address_components"]);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) return;
        const markerPosition = place.geometry.location;
        map.panTo(markerPosition);
        map.setZoom(15);
      });
    }

    return () => {
      cleanupResources();
    };
  }, [map]);

  const cleanupResources = () => {
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setMap(null);
    }
    clearCurrentShape();
  };

  const deleteAllShapes = () => {
    // Clear the current shape from the map
    clearCurrentShape();

    // Clear the geoRange value
    setFieldValue("geoRange", null);

    // Re-enable drawing controls
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setDrawingMode(null);
      drawingManagerRef.current.setOptions({
        drawingControl: true
      });
    }

    // Force a re-render of the map to ensure all shapes are cleared
    if (map) {
      google.maps.event.trigger(map, 'resize');
    }
  };

  return {
    drawingManager: drawingManagerRef.current,
    deleteAllShapes,
    cleanupResources,
    createShapeFromGeoJSON
  };
}

