import axios from "axios";
import { LocationPayload } from "../../components/Content/AdminConsole/Modals/Locations/types";

const apiDomain = process.env.REACT_APP_API_DOMAIN;

export const createLocation = async(payload:LocationPayload)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/locations`
            const response = await axios.post(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while create Workspaces location", error);
    }
}

export const editLocation = async(payload:LocationPayload,workspaceLocationId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/locations/${workspaceLocationId}`
            const response = await axios.put(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Edit Workspaces location", error);
    }
}

export const getAllLocations = async()=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/locations`
            const response = await axios.get(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while get All Workspaces location", error);
    }
}

export const deleteLocation = async(workspaceLocationId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/locations/${workspaceLocationId}`
            const response = await axios.delete(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Delete Workspaces location", error);
    }
}