import {Button} from "flowbite-react";
import {FC} from "react";
import usersImg from "./images/users.svg";

interface NoUsersScreenProps {
  onClickAddUser: () => void;
  isForUserGroup: any;
  message:string;
  isEdit?:boolean;
  buttonText:string;
}

const NoUsersScreen: FC<NoUsersScreenProps> = ({onClickAddUser, isForUserGroup,message,isEdit=true,buttonText}) => {
  return (
    <div className="flex flex-col h-full items-center justify-between">
      <div />
      <div className="flex flex-col items-center">
        <div className="text-lg text-gray-500 mb-4 font-medium">
          {message}
        </div>
        {isEdit && 
        <Button
          className="border border-indigo-700 bg-transparent text-indigo-700 h-[34px] w-full"
          onClick={onClickAddUser}
        >
          {buttonText}
        </Button>}
      </div>
      <div>
        <img src={usersImg} alt="users" />
      </div>
    </div>
  );
};

export default NoUsersScreen;
