import { ReactNode } from 'react';
import { LocationProvider } from './LocationContext';
import { APIProvider } from "@vis.gl/react-google-maps";

interface CombinedProvidersProps {
	children: ReactNode;
}

export const CombinedProviders = ({ children }: CombinedProvidersProps) => {
	const apikey = process.env.REACT_APP_GOOGLE_MAP_KEY ?? "";

	return (
			<APIProvider apiKey={apikey} libraries={["places", "drawing"]}>
				<LocationProvider>
					{children}
				</LocationProvider>
			</APIProvider>
	);
};