import axios from "axios";
import { DepartmentPayload } from "../../components/Content/AdminConsole/Modals/Locations/types";

const apiDomain = process.env.REACT_APP_API_DOMAIN;

export const createDepartment = async(payload:DepartmentPayload)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/departments`
            const response = await axios.post(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while create Workspaces location", error);
    }
}

export const editDepartment = async(payload:DepartmentPayload,departmentId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/departments/${departmentId}`
            const response = await axios.put(url,payload,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Edit Department", error);
    }
}

export const deleteDepartment = async(departmentId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/departments/${departmentId}`
            const response = await axios.delete(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Delete Department", error);
    }
}

export const getAllDepartments = async()=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/departments`
            const response = await axios.get(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while get All Departments", error);
    }
}
