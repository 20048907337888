import {  Dropdown } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {defaultColDef} from "../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import EditDepartment from "../Modals/Departments/EditDepartment";
import { UserContext } from "../../../../context/UserContext";
import EditDesignation from "../Modals/Designations/EditDesignation";

interface DesignationsListProps {
  tableHeight: number;
  searchText?: string;
  users: any[];
  statusValue?: any;
  onConfirmModal: (response: any) => void;
}

const Designations: FC<DesignationsListProps> = ({
  tableHeight,
  searchText,
  users,
  onConfirmModal
}) => {

  const userContext = useContext(UserContext);
  const tableRef = useRef<AgGridReact>(null);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openEditDesignationModal, setOpenEditDesignationModal] = useState(false);
  const toggleEditDesignationModal = () => setOpenEditDesignationModal((old) => !old);

  const onClickEditLocationicon = (user: any) => {
    setSelectedUser(user);
    toggleEditDesignationModal();
  };

  const onClicDeleteDesignation = (user: any) => {
    onConfirmModal({user,message:`Are You Confirm for Delete <strong>${user.designationname}</strong> Designation`});
  };

  const onCheckboxChange = () => {
    console.log("selected");
  };
  

  // Update the grid when locations change
  useEffect(() => {
    tableRef?.current?.api?.redrawRows({ rowNodes: userContext.designations });
  }, [userContext.designations]);
  
  const columnDefs: any = [
    {
      field: "designationname",
      headerName: "Designation Name",
      cellRenderer: (params: any) => (
        <div className="flex items-center pt-2">
          <div className="flex flex-row h-full text-sm font-semibold items-center">
            <div style={{ maxWidth: 220, overflow: "hidden" }}>
              {params?.data?.designationname}
            </div>
          </div>
          <div className="ml-auto">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <span>
                  <HiDotsVertical />
                </span>
              }
            >
                <Dropdown.Item
                  onClick={() => onClickEditLocationicon(params?.data)}
                >
                  Edit Designation
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClicDeleteDesignation(params?.data)}
                >
                   Delete Designation
                </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
      cellStyle: { border: "unset", position: "static" },
      minWidth: 350,
      unSortIcon: true,
      pinned: true,
    },
    {
      field: "employeecount",
      headerName: "User Count",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data.employeecount}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    }
  ];

  const noRowsComponent = () => {
    return (
      <div className="ag-overlay-no-rows-wraper">
        <div className="text-2xl text-gray-400">Nothing to display!</div>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
      <AgGridReact
        ref={tableRef}
        rowData={users}
        // getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onSelectionChanged={onCheckboxChange}
        noRowsOverlayComponent={noRowsComponent}
        quickFilterText={searchText}
        getRowStyle={() => ({
          background: "transparent",
          transform: "none",
          position: "relative",
        })}
        rowHeight={50}
        pagination={true}
        paginationPageSizeSelector={[10, 20, 50, 100]}
        paginationPageSize={10}
      />
      {openEditDesignationModal && 
      <EditDesignation
        open={openEditDesignationModal}
        toggleModal={toggleEditDesignationModal}
        selectedUser={selectedUser}
      />}
    </div>
  );
};

export default Designations;
