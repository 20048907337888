import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import TableOptions from "../TableHeader/UsersList";
import NoUsersScreen from "../NoUsersScreen";
import AccountsTable from "../TableBody/UsersList";
import AddEditUserModal from "../Modals/User/AddEditUserModal";
import { Button, Modal } from "flowbite-react";

const UsersList = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [statusValue, setStatusValue] = useState<any>([]);
  const [roleValue, setRoleValue] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const toggleAddUserModal = () => setOpenAddUserModal((old) => !old);
  const userContext = useContext(UserContext);
  const [modalText, setModalText] = useState("");

  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [userContext.users]);

  const handleInviteSuccess = (response: any) => {
    setOpenModal(true);
    let responseString = "";
    Object.entries(response).forEach(([key, value]) => {
      let valueParsed = typeof value === "string" ? JSON.parse(value) : value;
      const textColorClass = valueParsed.success
        ? "text-green-500"
        : "text-red-500";

      const message = `Email: ${valueParsed.email} -  ${valueParsed.message}`;

      responseString += `<div class="${textColorClass} flex items-center justify-center gap-2">${message}</div>`;
    });

    setModalText(responseString);
  };

  return (
    <>
      <TableOptions
        searchText={searchText}
        setSearchText={setSearchText}
        usersCount={userContext.users.length}
        onClickAddUser={toggleAddUserModal}
        setStatusValue={setStatusValue}
        setRoleValue={setRoleValue}
        roleValue={roleValue}
        statusValue={statusValue}
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {userContext.users?.length > 0 && tableHeight ? (
          <AccountsTable
            tableHeight={tableHeight}
            searchText={searchText}
            users={[]}
            statusValue={statusValue}
            roleValue={roleValue}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={toggleAddUserModal}
            message={'No Users Added'}
            isForUserGroup={false}
            buttonText={'Add User'}
          />
        )}
      </div>
      <AddEditUserModal
        open={openAddUserModal}
        toggleModal={toggleAddUserModal}
        existingUserEmails={userContext?.users.map((user: any) => user.email)}
        onInviteSuccess={handleInviteSuccess}
      />

      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {/* Render modalText as HTML (safely) */}
            <div dangerouslySetInnerHTML={{ __html: modalText }} />
            <div className="flex justify-center gap-4 my-2">
              <Button
                className="bg-primary-500 hover:bg-primary-700"
                onClick={() => setOpenModal(false)}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UsersList;
