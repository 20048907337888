import { useContext, useEffect, useRef, useState } from "react";
import TableHeader from "../TableHeader/TableHeader";
import NoUsersScreen from "../NoUsersScreen";
import TableBody from "../TableBody/Departments";
import AddDepartmentModal from "../Modals/Departments/AddDepartment";
import { Button, Modal } from "flowbite-react";
import { UserContext } from "../../../../context/UserContext";
import { deleteDepartment } from "../../../../Utilities/AdminConsole/workspace.department";

const Departments = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>(20);
  const [openAddDepartmentModal, setopenAddDepartmentModal] = useState(false);
  const toggleAddDepartmentModal = () => setopenAddDepartmentModal((old) => !old);
  const userContext = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({
    message:''
  });

  useEffect(()=>{userContext.fetchDepartments();},[])
  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [userContext.departments]);

  const onConfirmModal = (response: any) => {
    setOpenModal(true);
    setSelectedUser(response);
  };

  const handleDepartmentDelete=async()=>{
      try{
        await deleteDepartment(selectedUser.user.departmentid)
        userContext.fetchDepartments();
        setOpenModal(false);
      }
      catch(error){
        console.log("Erro While Delete Department :",error);
      }
    }

  return (
    <>
      
      <TableHeader
      addData={toggleAddDepartmentModal}
      fetchData={userContext.fetchDepartments}
      usersCount={userContext?.departments?.length}
      buttonText={'Add Department'}
      subtittle={`Total ${userContext.departments?.length} Departments`}
      tittle="Department"
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {userContext.departments && userContext.departments?.length > 0 && tableHeight ? (
          <TableBody
            tableHeight={tableHeight}
            users={userContext.departments}
            onConfirmModal={onConfirmModal}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={toggleAddDepartmentModal}
            message={'No Department Added'}
            isForUserGroup={false}
            isEdit={true}
            buttonText={'Add Department'}
          />
        )}
        {openAddDepartmentModal && 
         <AddDepartmentModal
          open={openAddDepartmentModal}
          toggleModal={toggleAddDepartmentModal}
         />}
              <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                 <Modal.Header />
                 <Modal.Body>
                   <div className="text-center">
                     <div dangerouslySetInnerHTML={{ __html: selectedUser.message }} />
                     <div className="flex justify-center gap-4 my-2">
                       <Button
                         className="bg-primary-500 hover:bg-primary-700"
                         onClick={handleDepartmentDelete}
                       >
                         OK
                       </Button>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
      </div>
    </>
  );
};

export default Departments;
