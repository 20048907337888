import { FC, useContext } from "react";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { Button } from "flowbite-react";
import { Formik } from "formik";
import { designationSchema } from "../../utils/validationSchema";
import { createDesignation } from "../../../../../Utilities/AdminConsole/workspace.designations";
import { UserContext } from "../../../../../context/UserContext";

interface AddDesignationModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
}

const AddDesignation: FC<AddDesignationModalProps> = ({
  open,
  toggleModal
}) => {
  const userContext = useContext(UserContext);
  const handlePost=async(values:any)=>{
    try{
     await createDesignation(values.designationName);
     toggleModal();
     userContext.fetchDesignations();
    }
    catch(error){
      console.log("Error While post >>",error);
    }
  }

  return (
    <RightDrawer open={open} handleOpen={toggleModal} wideView={false}>
      <div className="">
        <p className="text-xl font-semibold text-primary-500  absolute top-4">
          Add Designation
        </p>
        <Formik
          initialValues={{
            designationName: ""
          }}
          validationSchema={designationSchema}
          onSubmit={handlePost}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) =>{
           return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex gap-2 mt-4">
                <div className="flex-row gap-2 w-full ">
                  <div className="flex gap-2">
                    {/* Designation Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="designationName"
                          className="text-sm font-medium text-gray-700"
                        >
                          Designation Name
                        </label>
                      </div>
                      <input
                        type="text"
                        name="designationName"
                        value={values.designationName}
                        className={`${
                          errors.designationName
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.designationName && touched.designationName && (
                        <p className="text-red-500 text-xs">
                          {errors.designationName}
                        </p>
                      )}
                    </div>
                  </div>
                   
                  <div className="mt-4">
                    <Button
                      type="submit"
                         disabled={isSubmitting}
                      className=" p-1 bg-primary-500 hover:bg-primary-700 text-white"
                        isProcessing={isSubmitting}
                    > Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}}
        </Formik>
      </div>
    </RightDrawer>
  );
};
export default AddDesignation;
