import { FC, useContext, useEffect, useState } from "react";
import { ROLE_NAMES, ROLE_VALUES } from "../../utils/constants";
import { UserContext } from "../../../../../context/UserContext";
import { validateEmail } from "../../utils/helper";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { inviteUsers } from "../../../../../Utilities/inviteUsers";
import { Button, Label, Radio } from "flowbite-react";
import { editUser } from "../../../../../Utilities/editUser";

interface AddEditUserModalProps {
    open: boolean;
    toggleModal: () => void;
    selectedUser?: any;
    existingUserEmails: string[];
    onInviteSuccess: (response: any) => void;
}

const AddEditUserModal: FC<AddEditUserModalProps> = ({
    open,
    toggleModal,
    selectedUser,
    onInviteSuccess,
}) => {      
    const userContext = useContext(UserContext);
    const [name, setName] = useState(selectedUser?.full_name || "");
    const [email, setEmail] = useState(selectedUser?.email || "");
    const [role, setRole] = useState(selectedUser?.role || ROLE_VALUES.MEMBER);
    const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
    const [repeatedEmails, setRepeatedEmails] = useState<string[]>([]);
    const [chipEmails, setChipEmails] = useState<string[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const existingUserEmails = userContext.users.map((user: any) => {
        return user.email;
    });

    useEffect(() => {
        if (selectedUser) {
            setName(selectedUser?.full_name || "");
            setEmail(selectedUser?.email || "");
            setRole(selectedUser?.role || ROLE_VALUES.MEMBER);
        }
    }, [selectedUser]);

    const workspaceName = localStorage.getItem("workspaceName") || "";

    const validateAndFilterEmails = () => {
        const repeatedEmailList: string[] = [];
        const invalidEmailList: string[] = [];
        const uniqueEmails: string[] = [];

        chipEmails.forEach((email) => {
            if (!validateEmail(email)) {
                invalidEmailList.push(email);
            } else if (existingUserEmails.includes(email)) {
                repeatedEmailList.push(email);
            } else {
                uniqueEmails.push(email);
            }
        });

        setInvalidEmails(invalidEmailList);
        setRepeatedEmails(repeatedEmailList);

        return uniqueEmails;
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" || event.key === ",") {
            event.preventDefault();
            const trimmedValue = event.currentTarget.value.trim().toLowerCase();
            if (trimmedValue) {
                if (
                    !chipEmails.includes(trimmedValue) &&
                    !existingUserEmails.includes(trimmedValue)
                ) {
                    if (validateEmail(trimmedValue)) {
                        setChipEmails([...chipEmails, trimmedValue]);
                        setInvalidEmails([]);
                        setRepeatedEmails([]);
                        setEmail("");
                    } else if (!invalidEmails.includes(trimmedValue)) {
                        // Check to prevent repeated invalid entries
                        setInvalidEmails([...invalidEmails, trimmedValue]);
                    }
                } else {
                    if (chipEmails.includes(trimmedValue)) {
                        setEmail(""); // Clear input if duplicate within chips
                    } else if (!repeatedEmails.includes(trimmedValue)) {
                        setRepeatedEmails([...repeatedEmails, trimmedValue]);
                    }
                }
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setEmail(newValue);

        if (newValue.trim() === "") {
            setInvalidEmails([]);
            setRepeatedEmails([]);
        }
    };

    const removeChip = (emailToRemove: string) => {
        setChipEmails((prev) =>
            prev.filter((email) => email !== emailToRemove)
        );
        setInvalidEmails((prev) =>
            prev.filter((email) => email !== emailToRemove)
        );
        setRepeatedEmails((prev) =>
            prev.filter((email) => email !== emailToRemove)
        );
    };

    const inviteNewUsers = async (): Promise<void> => {
        try {
            setIsProcessing(true);
            const listOfEmails = validateAndFilterEmails();
            let roleText: string = "";

            switch (role) {
                case ROLE_VALUES.OWNER:
                    roleText = ROLE_NAMES.Owner;
                    break;
                case ROLE_VALUES.ADMIN:
                    roleText = ROLE_NAMES.Admin;
                    break;
                case ROLE_VALUES.MEMBER:
                    roleText = ROLE_NAMES.Member;
                    break;
                default:
                    roleText = "";
                    break;
            }

            if (listOfEmails.length > 0) {
                try {
                    const response = await inviteUsers(listOfEmails, roleText);

                    if (Array.isArray(response)) {
                        let respString: any = {};
                        response.forEach((user: any) => {
                            respString[user.email] = JSON.stringify(user);
                        });
                        onInviteSuccess(respString);
                    } else {
                        console.log("Unexpected response format:", response);
                        alert("Unexpected response format. Please try again.");
                    }
                    setEmail("");
                    setChipEmails([]);
                    toggleModal();
                } catch (error: any) {
                    console.log("Error inviting users:", error);
                    if (error.response) {
                        console.log(
                            "Server error:",
                            error.response.data.message
                        );
                        alert(error.response.data.message);
                    } else {
                        console.log("Client error:", error.message);
                        alert(error.message);
                    }
                }
            }
            userContext.fetchUsers();
        } catch (error: any) {
            console.log("Error in inviteNewUsers:", error);
            alert("An unexpected error occurred. Please try again.");
        } finally {
            setIsProcessing(false);
        }
    };

    const updateUser = async (user: any, updatedName: string) => {
        try {
            setIsProcessing(true);
            let response: any;
            response = await editUser(user, updatedName, null);
            if (response.status === 200) {
                onInviteSuccess(response);
            }
            toggleModal();
        } catch (error: any) {
            toggleModal();
            console.log("Edit Name error:", error);
            alert("Server error. Failed to edit name.");
        } finally {
            setIsProcessing(false);
            toggleModal();
        }
    };

    const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedUser) {
            await updateUser(selectedUser, name);
        } else {
            await inviteNewUsers();
        }
    };

    const isSubmitDisabled =
        !selectedUser && (chipEmails.length === 0 || invalidEmails.length > 0);

    return (
        <RightDrawer open={open} handleOpen={toggleModal}>
            <div className="">
                <p className="text-gray-500 ">
                    {selectedUser ? "Edit name for:" : `Invite people to:`}
                </p>
                <p className="text-xl font-semibold text-primary-500 py-1">
                    {workspaceName}
                </p>
                <form onSubmit={onSubmitForm}>
                    {selectedUser && (
                        <div className="mt-5">
                            <div className="mb-2 block">
                                <label
                                    htmlFor="name"
                                    className="text-sm font-medium text-gray-700">
                                    Full Name:
                                </label>
                            </div>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    )}
                    <div className="mt-5">
                        <div className="mb-2 block">
                            <label
                                htmlFor="email"
                                className="text-sm font-medium text-gray-700 p-2">
                                Email
                            </label>
                        </div>
                        <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-1 focus-within:border-primary-500">
                            {chipEmails.map((chipEmail, index) => (
                                <div
                                    key={index}
                                    className="bg-primary-200 hover:bg-primary-300 hover:shadow px-2 py-1 rounded-md flex items-center text-xs transition-all ease-in-out duration-200">
                                    <span className="text-sm font-medium">
                                        {chipEmail}
                                    </span>
                                    <button
                                        type="button"
                                        className="ml-1 text-black hover:text-red-500 focus:outline-none"
                                        onClick={() => removeChip(chipEmail)}>
                                        &times;
                                    </button>
                                </div>
                            ))}
                            <input
                                type="text"
                                value={email}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                className="flex-1 px-2 py-1 rounded border-none focus:ring-0 focus:border-transparent focus:outline-none placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:placeholder:opacity-0 disabled:opacity-40 disabled:cursor-not-allowed"
                                placeholder="Email Addresses"
                                disabled={!!selectedUser}
                            />
                        </div>
                        {!selectedUser && (
                            <div>
                                <p className="text-xs text-gray-500 italic my-1">
                                    Add an email and hit enter or comma for
                                    multiple entries.
                                </p>
                            </div>
                        )}
                        {invalidEmails.length > 0 && (
                            <div>
                                <p className="text-xs text-red-500">
                                    Invalid Emails: {invalidEmails.join(", ")}
                                </p>
                            </div>
                        )}
                        {repeatedEmails.length > 0 && (
                            <div>
                                <p className="text-xs text-red-500">
                                    Already existing emails:{" "}
                                    {repeatedEmails.join(", ")}
                                </p>
                            </div>
                        )}
                    </div>
                    {!selectedUser && (
                        <fieldset className="flex max-w-md gap-4 mt-10 p-1 w-full">
                            <legend className="text-sm font-medium text-gray-700 p-1">
                                Invite as:
                            </legend>
                            <div
                                className={`flex items-center gap-2 border hover:bg-primary-50 rounded-md p-2 transition-colors ease-in-out duration-300 ${
                                    role === ROLE_VALUES.ADMIN
                                        ? "bg-primary-200"
                                        : "bg-white"
                                }`}>
                                <input
                                    type="radio"
                                    id="account-admin"
                                    name="role"
                                    value={ROLE_VALUES.ADMIN.toString()}
                                    checked={role === ROLE_VALUES.ADMIN}
                                    onChange={() => setRole(ROLE_VALUES.ADMIN)}
                                    className="text-primary-500 focus:border-transparent focus:ring-transparent cursor-pointer"
                                />
                                <label
                                    htmlFor="account-admin"
                                    className="text-sm">
                                    Account Admin
                                </label>
                            </div>
                            <div
                                className={`flex items-center gap-2 border hover:bg-primary-50 rounded-md p-2 transition-colors ease-in-out duration-300 ${
                                    role === ROLE_VALUES.MEMBER
                                        ? "bg-primary-200"
                                        : "bg-white"
                                }`}>
                                <input
                                    type="radio"
                                    id="account-member"
                                    name="role"
                                    value={ROLE_VALUES.MEMBER.toString()}
                                    checked={role === ROLE_VALUES.MEMBER}
                                    onChange={() => setRole(ROLE_VALUES.MEMBER)}
                                    className="text-primary-500 focus:border-transparent focus:ring-transparent cursor-pointer"
                                />
                                <label
                                    htmlFor="account-member"
                                    className="text-sm">
                                    Account Member
                                </label>
                            </div>
                        </fieldset>
                    )}
                    <div className="w-full mt-10">
                        <Button
                            type="submit"
                            className="w-full bg-primary-500 hover:bg-primary-700 text-white"
                            disabled={
                                (!selectedUser && isSubmitDisabled) ||
                                isProcessing
                            }
                            isProcessing={isProcessing}>
                            {isProcessing
                                ? selectedUser
                                    ? "Editing User..."
                                    : "Sending Invite..."
                                : selectedUser
                                ? "Edit User"
                                : "Send Invitations"}
                        </Button>
                    </div>
                </form>
            </div>
        </RightDrawer>
    );
};

export default AddEditUserModal;




