import axios from "axios";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
export const getAllDesignations = async()=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/designations`
            const response = await axios.get(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while get All Designations", error);
    }
}

export const createDesignation = async(designationName:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/designations`
            const response = await axios.post(url,{designationName},{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while get All Designations", error);
    }
}

export const editDesignation = async(designationName:string,designationId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/designations/${designationId}`
            const response = await axios.put(url,{designationName},{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Edit Designation", error);
    }
}

export const deleteDesignation = async(designationId:string)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/designations/${designationId}`
            const response = await axios.delete(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Delete Designation", error);
    }
}