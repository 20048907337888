import { useContext, useEffect, useRef, useState } from "react";
import TableHeader from "../TableHeader/TableHeader";
import NoUsersScreen from "../NoUsersScreen";
import TableBody from "../TableBody/Locations";
import AddLocationModal from "../Modals/Locations/AddLocatinModal";
import { LocationContext } from "../../../../context/LocationContext";
import { Button, Modal } from "flowbite-react";
import { deleteLocation } from "../../../../Utilities/AdminConsole/workspace.location";

const Locations = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>(20);
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const toggleAddLocationModal = () => setOpenAddLocationModal((old) => !old);
  const locationContext = useContext(LocationContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({
    message:''
  });

  useEffect(()=>{
    locationContext.fetchLocations();
  },[])
  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [locationContext.locations]);

  const onConfirmModal = (response: any) => {
    setOpenModal(true);
    setSelectedUser(response);
  };

  const handleLocationDelete=async()=>{
    try{
      const resp = await deleteLocation(selectedUser.user.workspaceLocationId)
      locationContext.fetchLocations();
      setOpenModal(false);
    }
    catch(error){
      console.log("Erro While Delete Location :",error);
    }
  }

  return (
    <>
      <TableHeader
      addData={toggleAddLocationModal}
      fetchData={locationContext.fetchLocations}
      usersCount={locationContext.locations?.length}
      buttonText={'Add Location'}
      subtittle={`Total ${locationContext.locations?.length} Locations`}
      tittle="Locations"
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {locationContext.locations && locationContext.locations?.length > 0 && tableHeight ? (
          <TableBody
            tableHeight={tableHeight}
            users={locationContext.locations}
            onConfirmModal={onConfirmModal}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={toggleAddLocationModal}
            message={'No Location Added'}
            isForUserGroup={false}
            isEdit={true}
            buttonText={'Add Location'}
          />
        )}
        {openAddLocationModal && 
         <AddLocationModal
          open={openAddLocationModal}
          toggleModal={toggleAddLocationModal}
         />}
              <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                 <Modal.Header />
                 <Modal.Body>
                   <div className="text-center">
                     <div dangerouslySetInnerHTML={{ __html: selectedUser.message }} />
                     <div className="flex justify-center gap-4 my-2">
                       <Button
                         className="bg-primary-500 hover:bg-primary-700"
                         onClick={handleLocationDelete}
                       >
                         OK
                       </Button>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
      </div>
    </>
  );
};

export default Locations;
