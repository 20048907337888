import { FC, useContext, useEffect, useRef, useState } from "react";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { Button } from "flowbite-react";
import {
  editWorkspace,
  getWorkspacesList,
} from "../../../../../Utilities/workspace";
import { UserContext } from "../../../../../context/UserContext";

interface AddEditWorkspaceModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
  existingUserEmails: string[];
}

const AddEditWorkspaceModal: FC<AddEditWorkspaceModalProps> = ({
  open,
  toggleModal,
  selectedUser,
}) => {
  const imageref = useRef<any>();
  const userContext = useContext(UserContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [imageUrl, setImage] = useState<string | null>(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceSubdomain, setWorkspaceSubdomain] = useState("");

  const getworkspaces = async () => {
    try {
      const response = await getWorkspacesList();
      const {  subdomain, name } = response[0];
      setWorkspaceName(name);
      setWorkspaceSubdomain(subdomain);
    } catch (error) {
      console.log("Error while getting workspace :", error);
    }
  };
  useEffect(() => {
    if (open) {
      getworkspaces();
    }
  }, [open]);

  const isValid = () => {
    let error: any = {};
    let valid = true;
    if (workspaceName.trim() === "") {
      error.workspaceName = "Please Enter Workspace Name";
      valid = false;
    }
    setErrors(error);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (isValid()) {
        setIsProcessing(true);
        await editWorkspace(workspaceName, imageUrl);
        console.log("Payload >>", workspaceName, imageUrl);
        setIsProcessing(false);
        toggleModal();
        userContext.fetchWorkspaces();
      }
    } catch (error) {
      console.log("Error While Submit >>", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, files } = e.target;
    if (id === "workspaceName") {
      errors.workspaceName = undefined;
      setWorkspaceName(value);
    }
    if (id === "dropzone-file" && files?.[0]) {
      errors.imageUrl = undefined;
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setImage(base64);
        if (imageref.current) {
          imageref.current.src = base64;
        }
      };
      reader.readAsDataURL(file);
    }

    setErrors(errors);
  };

  return (
    <RightDrawer open={open} handleOpen={toggleModal}>
      <div className="">
        {/* <p className="text-gray-500 ">Edit Workspace </p> */}
        <p className="text-xl font-semibold text-primary-500  absolute top-4">
          Edit Workspace
        </p>
        <div className="flex p-3 items-center justify-center">
          <div className="flex flex-col items-center w-5/6 h-2/6">
            <label
              htmlFor="dropzone-file"
              className={`${
                errors.imageUrl
                  ? "border-red-300"
                  : "border-gray-300 bg-gray-50"
              } flex flex-col items-center justify-center p-2 h-40 border-2  border-dashed rounded-[5px] cursor-pointer   dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
            >
              <img
                ref={imageref}
                alt="image"
                src={
                  imageUrl
                    ? imageUrl
                    : `${process.env.REACT_APP_LINODE_ENDPOINT}${process.env.REACT_APP_LINODE_DEFAULT_BUCKET}/${selectedUser?.iconUrl}`
                }
                className="object-contain h-full w-full"
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://play.teleporthq.io/static/svg/default-img.svg";
                }}
              />
              <input
                id="dropzone-file"
                accept="image/png, image/jpg, image/jpeg"
                type="file"
                onChange={handleChange}
                className="hidden"
              />
            </label>
            {errors.imageUrl && (
              <p className="text-red-500 text-xs">{errors.imageUrl}</p>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <div className="mb-2 block">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700"
              >
                Workspace Name
              </label>
            </div>
            <input
              type="text"
              id="workspaceName"
              value={workspaceName}
              className={`${
                errors.workspaceName
                  ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                  : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
              } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
              onChange={handleChange}
            />
            {errors.workspaceName && (
              <p className="text-red-500 text-xs">{errors.workspaceName}</p>
            )}
          </div>
          <div className="mt-5">
            <div className="mb-2 block">
              <label
                htmlFor="workspaceSubdomain"
                className="text-sm font-medium text-gray-700"
              >
                Workspace Sub-Domain
              </label>
            </div>
            <input
              type="text"
              id="workspaceSubdomain"
              disabled={true}
              value={workspaceSubdomain}
              className="w-full px-3 disabled:cursor-not-allowed py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="w-full mt-10">
            <Button
              type="submit"
              className="w-full bg-primary-500 hover:bg-primary-700 text-white"
              disabled={isProcessing}
              isProcessing={isProcessing}
            >
              {isProcessing ? "Processing..." : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </RightDrawer>
  );
};

export default AddEditWorkspaceModal;
