import { useContext, useEffect, useRef, useState } from "react";
import TableHeader from "../TableHeader/TableHeader";
import NoUsersScreen from "../NoUsersScreen";
import TableBody from "../TableBody/Designations";
import { Button, Modal } from "flowbite-react";
import { UserContext } from "../../../../context/UserContext";
import AddDesignation from "../Modals/Designations/AddDesignation";
import { deleteDesignation } from "../../../../Utilities/AdminConsole/workspace.designations";

const Designations = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>(20);
  const [openAddDesignationModal, setopenAddDesignationModal] = useState(false);
  const toggleAddDesignationModal = () => setopenAddDesignationModal((old) => !old);
  const userContext = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({
    message:''  
  });

  useEffect(()=>{
    userContext.fetchDesignations();
  },[])
  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [userContext.designations]);

  const onConfirmModal = (response: any) => {
    setOpenModal(true);
    setSelectedUser(response);
  };


   const handleDesignationDelete=async()=>{
        try{
          await deleteDesignation(selectedUser.user?.designationid)
          userContext.fetchDesignations();
          setOpenModal(false);
        }
        catch(error){
          console.log("Erro While Delete Designation :",error);
        }
      }

  return (
    <>
      
      <TableHeader
      addData={toggleAddDesignationModal}
      fetchData={userContext.fetchDesignations}
      usersCount={userContext.designations?.length}
      buttonText={'Add Designation'}
      subtittle={`Total ${userContext.designations?.length} Designation's`}
      tittle="Designation"
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {userContext.designations && userContext.designations?.length > 0 && tableHeight ? (
          <TableBody
            tableHeight={tableHeight}
            users={userContext.designations}
            onConfirmModal={onConfirmModal}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={toggleAddDesignationModal}
            message={'No Designation Added'}
            isForUserGroup={false}
            isEdit={true}
            buttonText={'Add Designation'}
          />
        )}
        {openAddDesignationModal && 
         <AddDesignation
          open={openAddDesignationModal}
          toggleModal={toggleAddDesignationModal}
         />}
              <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                 <Modal.Header />
                 <Modal.Body>
                   <div className="text-center">
                     <div dangerouslySetInnerHTML={{ __html: selectedUser.message }} />
                     <div className="flex justify-center gap-4 my-2">
                       <Button
                         className="bg-primary-500 hover:bg-primary-700"
                         onClick={handleDesignationDelete}
                       >
                         OK
                       </Button>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
      </div>
    </>
  );
};

export default Designations;
