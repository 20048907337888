import { FC} from "react";
import Header from "../UserPortal/Header";
import Sidebar from "./Sidebar";
import { customTheme } from "../../../CommonUtilities/CustomThemes";
import { Flowbite } from "flowbite-react";
import { Outlet } from "react-router-dom";
import { CombinedProviders } from "../../../context/CombinedProviders";

const AdminConsole: FC = () => {
  return (
    <CombinedProviders>
    <Flowbite theme={{ theme: customTheme }}>
      <div className="w-full h-screen flex flex-col">
        <Header />
        <div className="flex flex-1 h-full w-full overflow-hidden">
          <Sidebar />
          <div className="flex-1 flex flex-col p-4 pb-0 overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </Flowbite>
  </CombinedProviders>
  );
};

export default AdminConsole;
