import axios from "axios";

export const getWorkspacesList = async()=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

        const apiDomain: string =
          window.location.origin !== "http://localhost:3001"
            ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
            : "http://localhost:3000";

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}`
            const response = await axios.get(url,{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while getting Workspaces list", error);
    }
}

export const editWorkspace = async(name:string,iconUrl:string | null)=>{
    try{
        const accessToken = localStorage.getItem("accessToken");
        const workspaceId = localStorage.getItem("workspaceId");
        const bearerToken = `Bearer ${accessToken}`;

        const apiDomain: string =
          window.location.origin !== "http://localhost:3001"
            ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
            : "http://localhost:3000";

            const url = `${apiDomain}/api/v2/workspaces/${workspaceId}`
            const response = await axios.put(url,{name,iconUrl},{ headers: { 'Authorization': bearerToken } })
            return response.data;
    }
    catch(error){
        console.log("🚀 ~ Error while Edit Workspaces", error);
    }
}