import {createBrowserRouter} from "react-router-dom";
import App from "../../App";
import LoginFlow from "../Content/LoginPage/LoginFlow";
import LoggedInUserPortal from "../Content/UserPortal/LoggedInUserPortal";
import VerifyMagicLink from "../Content/VerifyMagicLink/VerifyMagicLink";
import ClickSelfie from "../Content/ClickSelfie";
import CreateOrganization from "../Content/LoginPage/CreateOrganization";
import LoginSelectOrganization from "../Content/LoginPage/LoginSelectOrganization";
import AdminConsole from "../Content/AdminConsole";
import AcceptInvitation from "../Content/AdminConsole/AcceptInvitation";
import UsersList from "../Content/AdminConsole/Components/UsersList";
import BasicDetails from "../Content/AdminConsole/Components/BasicDetails";
import Locations from "../Content/AdminConsole/Components/Locations";
import Departments from "../Content/AdminConsole/Components/Departments";
import Employee from "../Content/AdminConsole/Components/Employee";
import Designations from "../Content/AdminConsole/Components/Designations";

export const ROUTER: any = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "signin",
    element: <LoginFlow />
  },
  {
    path: "portal",
    element: <LoggedInUserPortal />
  },
  {
    path: "createOrganization",
    element: <CreateOrganization />
  },
  {
    path: "selectOrganization",
    element: <LoginSelectOrganization />
  },
  {
    path: "verify",
    element: <VerifyMagicLink /> // Add a route for the magic link verification
  },
  {
    path: "click-selfie",
    element: <ClickSelfie />
  },
  {
    path: "admin",
    element: <AdminConsole />,
    children: [
      {
        path: "users-list",
        element: <UsersList />
      },
      {
        path: "workspaces/basic-details",
        element: <BasicDetails />
      },
      {
        path: "workspaces/locations",
        element: <Locations />
      },
      {
        path: "workspaces/departments",
        element: <Departments />
      },
      {
        path: "workspaces/designations",
        element: <Designations />
      },
      {
        path: "employee",
        element: <Employee />
      }
    ]
  },
  {
    path: "admin/groups",
    element: <AdminConsole />
  },
  {
    path: "acceptInvitation",
    element: <AcceptInvitation />
  }
]);
