import {  Dropdown } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {defaultColDef} from "../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import EditLocationModal from "../Modals/Locations/EditLocationsModal";
import { LocationContext } from "../../../../context/LocationContext";

interface LocationsListProps {
  tableHeight: number;
  searchText?: string;
  users: any[];
  statusValue?: any;
  onConfirmModal: (response: any) => void;
}

const Locations: FC<LocationsListProps> = ({
  tableHeight,
  searchText,
  users,
  onConfirmModal
}) => {

  const locationContext = useContext(LocationContext);
  const tableRef = useRef<AgGridReact>(null);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const toggleEditLocationModal = () => setOpenEditLocationModal((old) => !old);

  const onClickEditLocationicon = (user: any) => {
    setSelectedUser(user);
    toggleEditLocationModal();
  };

  const onClicDeleteLocationicon = (user: any) => {
    onConfirmModal({user,message:`Are You Confirm for Delete ${user.locationName} Location`});
  };

  const onCheckboxChange = () => {
    console.log("selected");
  };
  

  // Update the grid when locations change
  useEffect(() => {
    tableRef?.current?.api?.redrawRows({ rowNodes: locationContext.locations });
  }, [locationContext.locations]);

  const getRowId = (params: any) => {
    return params.data.id || `${params.data.locationName}_${params.data.postalCode}`;
  };

  const columnDefs: any = [
    {
      field: "locationName",
      headerName: "Location Name",
      cellRenderer: (params: any) => (
        <div className="flex items-center pt-2">
          <div className="flex flex-row h-full text-sm font-semibold items-center">
            <div style={{ maxWidth: 220, overflow: "hidden" }}>
              {params?.data?.locationName}
            </div>
          </div>
          <div className="ml-auto">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <span>
                  <HiDotsVertical />
                </span>
              }
            >
                <Dropdown.Item
                  onClick={() => onClickEditLocationicon(params?.data)}
                >
                  Edit Location Info
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClicDeleteLocationicon(params?.data)}
                >
                   Delete Location Info
                </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
      cellStyle: { border: "unset", position: "static" },
      minWidth: 350,
      unSortIcon: true,
      pinned: true,
    },
    {
      field: "addressLine1",
      headerName: "Address Line 1",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.addressLine1}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "state",
      headerName: "State",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.state}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "city",
      headerName: "City",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.city}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "postalCode",
      headerName: "Pin Code",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.postalCode}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    }
  ];

  const noRowsComponent = () => {
    return (
      <div className="ag-overlay-no-rows-wraper">
        <div className="text-2xl text-gray-400">Nothing to display!</div>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
      <AgGridReact
        ref={tableRef}
        rowData={users}
        getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onSelectionChanged={onCheckboxChange}
        noRowsOverlayComponent={noRowsComponent}
        quickFilterText={searchText}
        getRowStyle={() => ({
          background: "transparent",
          transform: "none",
          position: "relative",
        })}
        rowHeight={50}
        pagination={true}
        paginationPageSizeSelector={[10, 20, 50, 100]}
        paginationPageSize={10}
      />
      {openEditLocationModal && 
      <EditLocationModal
        open={openEditLocationModal}
        toggleModal={toggleEditLocationModal}
        selectedUser={selectedUser}
      />}
    </div>
  );
};

export default Locations;
