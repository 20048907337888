import { FC,  useContext } from "react";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { Button, Select } from "flowbite-react";
import { Formik } from "formik";
import cuntryFile from '../../../../../assets/countries.json'
import { locationSchema } from "../../utils/validationSchema";
import { LocationPayload } from "./types";
import Drawing from "./Map/drawing";
import { createLocation } from "../../../../../Utilities/AdminConsole/workspace.location";
import { LocationContext } from "../../../../../context/LocationContext";

interface AddLocationModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
}

const AddLocationModal: FC<AddLocationModalProps> = ({
  open,
  toggleModal
}) => {
  const locationContext = useContext(LocationContext);
  const handlePost=async(values:any)=>{
    try{
      let payload :LocationPayload= {
        ...values,
        locationName: values.locationName,
        locationDescription: values.description,
        addressLine1: values.formattedAddress
      }
      await createLocation(payload);
      toggleModal();
      locationContext.fetchLocations();
    }
    catch(error){
      console.log("Error While post >>",error);
    }
  }

  return (
    <RightDrawer open={open} handleOpen={toggleModal} wideView={true}>
      <div className="">
        <p className="text-xl font-semibold text-primary-500  absolute top-4">
          Add Location
        </p>
        <Formik
          initialValues={{
            locationName: "",
            description: "",
            formattedAddress: "",
            addressLine2: "",
            city: "",
            country: "",
            state: "",
            postalCode: "",
            geoRange: {},
          }}
          enableReinitialize
          validationSchema={locationSchema}
          onSubmit={handlePost}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) =>{
           return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex gap-2 mt-4">
                <div className="flex-row gap-2 w-2/6 ">
                  <div className="flex gap-2">
                    {/* LocationName */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="name"
                          className="text-sm font-medium text-gray-700"
                        >
                          Location Name
                        </label>
                      </div>
                      <input
                        type="text"
                        name="locationName"
                        value={values.locationName}
                        className={`${
                          errors.locationName
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.locationName && touched.locationName && (
                        <p className="text-red-500 text-xs">
                          {errors.locationName}
                        </p>
                      )}
                    </div>
                  </div>
                    {/* Description */}
                  <div className="mt-2">
                    <div className="mb-2 block">
                      <label
                        htmlFor="description"
                        className="text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                    </div>
                    <textarea
                      name="description"
                      value={values.description}
                      className={`${
                        errors.description
                          ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                          : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                      } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <p className="text-red-500 text-xs">
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div className="flex gap-2 mt-2">
                    <div className="flex-1 w-2/6">
                      <div className="mb-2 block">
                        <label
                          htmlFor="formattedAddress"
                          className="text-sm font-medium text-gray-700"
                        >
                          Address Line 1
                        </label>
                      </div>
                      <textarea
                        rows={3}
                        name="formattedAddress"
                        value={values.formattedAddress}
                        className={`${
                          errors.formattedAddress
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.formattedAddress && touched.formattedAddress && (
                        <p className="text-red-500 text-xs">
                          {errors.formattedAddress}
                        </p>
                      )}
                    </div>
                    <div className="flex-1 w-2/6">
                      <div className="mb-2 block">
                        <label
                          htmlFor="addressLine2"
                          className="text-sm font-medium text-gray-700"
                        >
                          Address Line 2
                        </label>
                      </div>
                      <textarea
                        rows={3}
                        name="addressLine2"
                        value={values.addressLine2}
                        className={`${
                          errors.addressLine2
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.addressLine2 && touched.addressLine2 && (
                        <p className="text-red-500 text-xs">
                          {errors.addressLine2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 mt-2">
                    {/* State */}
                    <div className="w-3/6">
                    <div className="mb-2 block w-full">
                      <label
                          htmlFor="state"
                          className="text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <Select
                        name="state"
                        value={values.state}
                        onBlur={handleBlur}
                        className={`${
                          errors.state
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                        onChange={handleChange}
                        >
                          {values.country !== '' ? 
                         cuntryFile
                          .filter((country: any) => country.name === values.country) // Find the matching country
                          .flatMap((country: any) =>
                            country.states.map((state: any) => (
                              <option key={state.code} value={state.name}>
                                {state.name}
                              </option>
                            )))
                          :
                          <option value="">Select State</option>}
                        </Select>
                        {errors.state && touched.state && (
                        <p className="text-red-500 text-xs">{errors.state}</p>
                      )}

                    </div>
                    </div>

                    {/* city */}
                    <div className="w-3/6">
                      <div className="mb-2 block">
                        <label
                          htmlFor="city"
                          className="text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                      </div>
                      <input
                        type="text"
                        name="city"
                        value={values.city}
                        className={`${
                          errors.city
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.city && touched.city && (
                        <p className="text-red-500 text-xs">{errors.city}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 mt-2">
                    
                  {/* Country  */}
                   <div className="w-3/6 ">
                    <div className="mb-2 block ">
                      <label
                          htmlFor="country"
                          className="text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <Select
                        name="country"
                        value={values.country}
                        onBlur={handleBlur}
                        className={`${
                          errors.country
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                        onChange={handleChange}
                        >
                          <option value="">Select Country</option>
                          {cuntryFile.map((country:any) => (
                            <option key={country.code2}  value={country.name}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Select>
                        {errors.country && touched.country && (
                        <p className="text-red-500 text-xs">{errors.country}</p>
                      )}

                    </div>
                  </div>

                  {/* Postal Code */}
                  <div className="w-3/6">
                    <div className="mb-2 block">
                      <label
                        htmlFor="postalCode"
                        className="text-sm font-medium text-gray-700"
                      >
                        Postal Code
                      </label>
                    </div>
                    <input
                      type="text"
                      name="postalCode"
                      value={values.postalCode}
                      className={`${
                        errors.postalCode
                          ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                          : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                      } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.postalCode && touched.postalCode && (
                      <p className="text-red-500 text-xs">
                        {errors.postalCode}
                      </p>
                    )}
                  </div>
                  </div>
                 
                  <div>
                    <Button
                      type="submit"
                         disabled={isSubmitting}
                      className="mt-2  p-1 bg-primary-500 hover:bg-primary-700 text-white"
                        isProcessing={isSubmitting}
                    > Save
                    </Button>
                  </div>
                </div>
                <div className="flex-row w-5/6">
                  <div className={`${errors.geoRange ? 'border-red-300 border focus:ring-red-500 focus:border-red-500' : ''} p-2 rounded-lg flex-none`}>
                    <div className="mb-2 flex items-center gap-4 ">
                      <label
                        htmlFor="geoRange"
                        className="text-sm font-medium text-gray-700">
                        Geo Range
                      </label>
                      {errors.geoRange && touched.geoRange && typeof errors.geoRange === "string" && (
                      <p className="text-red-500 text-xs">
                        {errors.geoRange}
                      </p>
                    )}
                    </div>
                    <Drawing isEdit={false} setFieldValue={setFieldValue} geoRange={values.geoRange}/>
                  </div>
                </div>
              </div>
            </form>
          )}}
        </Formik>
      </div>
    </RightDrawer>
  );
};
export default AddLocationModal;
