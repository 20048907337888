'use client';

import "./Dashboard.css";
import CircleLogo from "./images/Circle_Logo.svg";
import HeroImg from "./images/Image.svg";
import { useNavigate } from "react-router-dom";
import { Button, Navbar } from "flowbite-react";
import DownloadButtons from "../../Common/DownloadButtons";

export default function   Dashboard() {
    const navigate = useNavigate();
    return (
      <>
        <div className="w-full h-screen bg-primary-700 overflow-hidden">
          <div className="py-2 w-screen flex md:flex-row justify-end ">
            <button
              onClick={() => {
                navigate("/signin?flow=email");
              }}
              type="button"
              className="mx-6 px-3 py-2 text-xs font-semibold text-center bg-gray-200 hover:bg-white hover:shadow-2xl text-primary-500 rounded-lg   focus:ring-4 focus:outline-none focus:ring-primary-300-300"
            >
              Sign In
            </button>
          </div>

          <div className="flex flex-col lg:flex-row justify-center">
            <div
              className="hidden lg:w-[50vw]  xl:w-[60vw] lg:flex items-center mb-32 rounded-r-xl bg-cover"
              style={{
                backgroundImage: `url(${HeroImg})`,
                backgroundPosition: "right",
                backgroundSize: "auto 120%",
              }}
            >
              {/* Hero Image */}
            </div>
            <div className="xl:w-[40vw]  flex flex-col items-center justify-center h-screen">
              <div className="flex flex-col items-center">
                <div className="flex items-center gap-2">
                  <img
                    src={CircleLogo}
                    alt="Circle Logo"
                    className="h-20 w-auto"
                  />
                  <p className="text-white font-semibold text-7xl">Circle</p>
                </div>
                <p className="text-white tracking-wider text-xl text-center">
                  The Future of Collaboration!
                </p>
              </div>
              <p className="text-gray-200 p-8 text-center max-w-xl">
                Empower Your Team with Next-Generation Solutions for 3D Virtual
                Communication and Collaboration, Redefining How You Work
                Together.
              </p>
              <div>
                <p className="text-center text-gray-200 mt-[10vh]">
                  Install Circle App
                </p>
                <DownloadButtons buttonClassName="bg-gray-200 hover:bg-white hover:shadow-xl" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
}


