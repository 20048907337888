import { useContext, useEffect, useRef, useState } from "react";
import TableHeader from "../TableHeader/TableHeader";
import NoUsersScreen from "../NoUsersScreen";
import TableBody from "../TableBody/Employee";
import { Button, Modal } from "flowbite-react";
import AddEmployee from "../Modals/Employee/AddEmployee";
import { UserContext } from "../../../../context/UserContext";
import { deleteEmployeeDetails } from "../../../../Utilities/AdminConsole/workspace.employee.details";

const Employee = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>(20);
  const [openAddEmployeeModal, setopenAddEmployeeModal] = useState(false);
  const toggleAddEmployeeModal = () => setopenAddEmployeeModal((old) => !old);
  const userContext = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>({
    message:''
  });
  useEffect(()=>{
    userContext.fetchEmployeeList();
    userContext.fetchDepartments();
    userContext.fetchDesignations();
    userContext.fetchUsers();
  },[])
  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [userContext.employeeList]);

  const onConfirmModal = (response: any) => {
    setOpenModal(true);
    setSelectedUser(response);
  };
   const handleEmployeeDelete=async()=>{
          try{
            await deleteEmployeeDetails(selectedUser.user?.employeeid)
            userContext.fetchEmployeeList();
            setOpenModal(false);
          }
          catch(error){
            console.log("Erro While Delete Employee :",error);
          }
        }

  return (
    <>
      <TableHeader
      addData={toggleAddEmployeeModal}
      fetchData={userContext.fetchEmployeeList}
      usersCount={userContext.employeeList?.length}
      buttonText={'Add Employee'}
      subtittle={`Total ${userContext.employeeList?.length} Employee's`}
      tittle="Employee Details"
      />
      <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
        {userContext.employeeList && userContext.employeeList?.length > 0 && tableHeight ? (
          <TableBody
            tableHeight={tableHeight}
            users={userContext.employeeList}
            onConfirmModal={onConfirmModal}
          />
        ) : (
          <NoUsersScreen
            onClickAddUser={toggleAddEmployeeModal}
            message={'No Employee Added'}
            isForUserGroup={false}
            isEdit={true}
            buttonText={'Add Employee'}
          />
        )}
        {openAddEmployeeModal && 
         <AddEmployee
          open={openAddEmployeeModal}
          toggleModal={toggleAddEmployeeModal}
         />}
              <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
                 <Modal.Header />
                 <Modal.Body>
                   <div className="text-center">
                     <div dangerouslySetInnerHTML={{ __html: selectedUser.message }} />
                     <div className="flex justify-center gap-4 my-2">
                       <Button
                         className="bg-primary-500 hover:bg-primary-700"
                         onClick={handleEmployeeDelete}
                       >
                         OK
                       </Button>
                     </div>
                   </div>
                 </Modal.Body>
               </Modal>
      </div>
    </>
  );
};

export default Employee;
