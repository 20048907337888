import { FC,  useContext } from "react";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { Button, Select } from "flowbite-react";
import { Formik } from "formik";
import { departmentSchema } from "../../utils/validationSchema";
import { UserContext } from "../../../../../context/UserContext";
import { editDepartment } from "../../../../../Utilities/AdminConsole/workspace.department";
import { DepartmentPayload } from "../Locations/types";

interface EditDepartmentModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
}

const EditDepartment: FC<EditDepartmentModalProps> = ({
  open,
  toggleModal,
  selectedUser
}) => {
  const userContext = useContext(UserContext);
  const handlePost=async(values:any)=>{
    try{
      let payload :DepartmentPayload= {
        parentDepartmentId: values.parentDepartmentId != "" ? values.parentDepartmentId : null,
        departmentName: values.departmentName
            }
      await editDepartment(payload,selectedUser?.departmentid);
      userContext.fetchDepartments();
      toggleModal();
    }
    catch(error){
      console.log("Error While post >>",error);
    }
  }

  return (
    <RightDrawer open={open} handleOpen={toggleModal} wideView={false}>
      <div className="">
        <p className="text-xl font-semibold text-primary-500  absolute top-4">
          Edit Department
        </p>
        <Formik
          initialValues={{
            departmentName: selectedUser?.departmentname ?? "",
            parentDepartmentId: selectedUser?.parentdepartmentid ?? "",
          }}
          validationSchema={departmentSchema}
          onSubmit={handlePost}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) =>{
           return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex gap-2 mt-4">
                <div className="flex-row gap-2 w-full ">
                  <div className="flex gap-2 mt-3">
                    {/* Department name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="departmentName"
                          className="text-sm font-medium text-gray-700"
                        >
                          Department Name
                        </label>
                      </div>
                      <input
                        type="text"
                        name="departmentName"
                        value={values.departmentName}
                        className={`${
                          errors.departmentName
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.departmentName && touched.departmentName && (
                        <p className="text-red-500 text-xs">
                          {errors.departmentName?.toString()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 mt-3">
                    {/* Parent Department */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="parentDepartmentId"
                          className="text-sm font-medium text-gray-700"
                        >
                          Parent Department
                        </label>
                        <Select
                        name="parentDepartmentId"
                        value={values.parentDepartmentId}
                        onBlur={handleBlur}
                        className={`${
                          errors.parentDepartmentId
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                        onChange={handleChange} >
                          <option value="">--Select--</option>
                          {userContext.departments?.length > 0 &&
                            userContext.departments.map((val: any) => (
                              val.departmentid !== selectedUser?.departmentid &&
                              <option key={val.departmentid} value={val.departmentid}>
                                {val.departmentname}
                              </option>
                            ))}
                        </Select>
                      </div>
                      {errors.parentDepartmentId && touched.parentDepartmentId && (
                        <p className="text-red-500 text-xs">
                          {errors.parentDepartmentId?.toString()}
                        </p>
                      )}
                    </div>
                  </div> 
                  <div className="mt-4">
                    <Button
                      type="submit"
                         disabled={isSubmitting}
                         className=" p-1 bg-primary-500 hover:bg-primary-700 text-white"
                         isProcessing={isSubmitting}> Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}}
        </Formik>
      </div>
    </RightDrawer>
  );
};
export default EditDepartment;
