import {  Dropdown } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {defaultColDef} from "../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import { UserContext } from "../../../../context/UserContext";
import EditEmployee from "../Modals/Employee/EditEmployee";

interface EmployeelistProps {
  tableHeight: number;
  searchText?: string;
  users: any[];
  statusValue?: any;
  onConfirmModal: (response: any) => void;
}

const Employee: FC<EmployeelistProps> = ({
  tableHeight,
  searchText,
  users,
  onConfirmModal
}) => {

  const userContext = useContext(UserContext);
  const tableRef = useRef<AgGridReact>(null);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openEditEmployeeModal, setOpenEditEmployeeModal] = useState(false);
  const toggleEditEmployeeModal = () => setOpenEditEmployeeModal((old) => !old);

  const onClickEditLocationicon = (user: any) => {
    setSelectedUser(user);
    toggleEditEmployeeModal();
  };

  const onCheckboxChange = () => {
    console.log("selected");
  };
  
  const onClicDeleteEmployee = (user: any) => {
    onConfirmModal({user,message:`Are You Confirm for Delete <strong>${user.fullname}</strong>`});
  };

  // Update the grid when locations change
  useEffect(() => {
    tableRef?.current?.api?.redrawRows({ rowNodes: userContext.employeeList });
  }, [userContext.employeeList]);

  const columnDefs: any = [
    {
      field: "fullname",
      headerName: "Employee Name",
      cellRenderer: (params: any) => (
        <div className="flex items-center pt-2">
          <div className="flex flex-row h-full text-sm font-semibold items-center">
            <div style={{ maxWidth: 220, overflow: "hidden" }}>
              {params?.data?.fullname}
            </div>
          </div>
          <div className="ml-auto">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <span>
                  <HiDotsVertical />
                </span>
              }
            >
                <Dropdown.Item
                  onClick={() => onClickEditLocationicon(params?.data)}
                >
                  Edit Employee
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClicDeleteEmployee(params?.data)}
                >
                   Delete Employee
                </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
      cellStyle: { border: "unset", position: "static" },
      minWidth: 250,
      unSortIcon: true,
      pinned: true,
    },
    {
      field: "email",
      headerName: "Email",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data.email}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "departmentname",
      headerName: "Department",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data.departmentname}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "designationname",
      headerName: "Designation",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data.designationname}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "reportingmanagername",
      headerName: "Reporting Manager",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.reportingmanagername}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    }
  ];

  const noRowsComponent = () => {
    return (
      <div className="ag-overlay-no-rows-wraper">
        <div className="text-2xl text-gray-400">Nothing to display!</div>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
      <AgGridReact
        ref={tableRef}
        rowData={users}
        // getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onSelectionChanged={onCheckboxChange}
        noRowsOverlayComponent={noRowsComponent}
        quickFilterText={searchText}
        getRowStyle={() => ({
          background: "transparent",
          transform: "none",
          position: "relative",
        })}
        rowHeight={50}
        pagination={true}
        paginationPageSizeSelector={[10, 20, 50, 100]}
        paginationPageSize={10}
      />
      {openEditEmployeeModal && 
      <EditEmployee
        open={openEditEmployeeModal}
        toggleModal={toggleEditEmployeeModal}
        selectedUser={selectedUser}
      />}
    </div>
  );
};

export default Employee;
