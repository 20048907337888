import * as Yup from 'yup';

export const locationSchema = Yup.object().shape({
    locationName:Yup.string().required('Location Name is Required').max(100, 'Location Name cannot exceed 100 characters'),
    country: Yup.string().required('Country Name is required').max(100, 'Country Name cannot exceed 100 characters'),
    city: Yup.string().required('City is required').max(100, 'City cannot exceed 100 characters'),
    state:Yup.string().required('State is required').max(100, 'State cannot exceed 100 characters'),
    description: Yup.string().max(200, 'Description cannot exceed 200 characters'),
    formattedAddress: Yup.string().required('AddressLine1 is required').max(300, 'AddressLine1 cannot exceed 300 characters'),
    addressLine2: Yup.string().max(300, 'AddressLine2 cannot exceed 300 characters'),
    postalCode:Yup.string().required('Postal Code is required').max(6, 'Postal Code cannot exceed 6 characters'),
    geoRange: Yup.object()
    .required("geoRange is required")
    .test("is-not-empty", "Select any location", (value) => {
      return value && Object.keys(value).length > 0;
    })
});

export const departmentSchema = Yup.object().shape({
  departmentName:Yup.string().required('Department Name is Required').max(100, 'Department Name cannot exceed 100 characters'),
  parentDepartmentId:Yup.string().max(100, 'Parent Department cannot exceed 100 characters'),
});

export const designationSchema = Yup.object().shape({
  designationName:Yup.string().required('Designation Name is Required').max(100, 'Designation Name cannot exceed 100 characters'),
});

export const employeeSchema = Yup.object().shape({
  employeeName: Yup.string().required('Employee Name is required').max(100, 'Employee Name cannot exceed 100 characters'),
  employeeEmail: Yup.string().email('Invalid email format').required('Email is required').max(100, 'Email cannot exceed 100 characters'),
  designationId: Yup.string().max(100, 'Designation Name cannot exceed 100 characters'),
  reportingManagerId: Yup.string().required('Reporting Manager is required').max(100, 'Reporting Manager cannot exceed 100 characters'),
  departmentId: Yup.string().max(100, 'Department Name cannot exceed 100 characters'),
});
