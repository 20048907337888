import React from "react";
import { Map } from "@vis.gl/react-google-maps";
import { useDrawingManager } from "./use-drawing-manager";
import { Button } from "flowbite-react"; 
import { locationAddressData } from "../types";
interface MapComponentProps {
  loading?: boolean;
  error?: { message: string; details: string } | null;
  locationData?: (data: locationAddressData,geojson:any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  geoRange:{}
  isEdit:boolean
}
const MapContainer : React.FC<MapComponentProps>=({setFieldValue,geoRange,isEdit=false}) => {
  const { deleteAllShapes} = useDrawingManager(null, {setFieldValue,geoRange,isEdit}); 
  return (
    <div className="relative flex flex-row">
      <input
        id="place-input"
        className="absolute top-4 left-2 rounded-lg z-[1000] w-4/6"
        type="text"
        placeholder="Search for places..."
      />
      <div id="controls" className="absolute top-16 left-2 z-[1000] w-2/6">
        <Button
          id="delete-shapes-btn"
          onClick={deleteAllShapes}
          className="p-1 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-200 ease-in-out"
        > Delete Shape
        </Button>
      </div>
      <Map
        defaultZoom={16}
        defaultCenter={{ lat: 29.2772598, lng: 77.7338472 }}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        style={{ height: "80vh", width: "100%" }}
      />
    </div>
  );
};

export default MapContainer;
