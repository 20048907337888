import { FC, useContext } from "react";
import RightDrawer from "../../../../Reusable/RightDrawer";
import { Button, Select } from "flowbite-react";
import { Formik } from "formik";
import {  employeeSchema } from "../../utils/validationSchema";
import { UserContext } from "../../../../../context/UserContext";
import {  editEmployeeDetails } from "../../../../../Utilities/AdminConsole/workspace.employee.details";

interface EditEmployeeModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
}

const EditEmployee: FC<EditEmployeeModalProps> = ({
  open,
  toggleModal,
  selectedUser
}) => {
  const userContext = useContext(UserContext);
  const handlePost=async(values:any)=>{
    try{
      let payload = {
        employeeName:values?.employeeName ,
        email:values?.employeeEmail ,
        designationId: values?.designationId != "" ? values?.designationId : null,
        reportingManagerId: values?.reportingManagerId != "" ? values?.reportingManagerId  : null,
        departmentId:values?.departmentId != "" ? values?.departmentId : null
      }
     await editEmployeeDetails(payload,selectedUser?.employeeid);
     toggleModal();
     userContext.fetchEmployeeList();
    }
    catch(error){
      console.log("Error While Edit Employee >>",error);
    }
  }

  return (
    <RightDrawer open={open} handleOpen={toggleModal} wideView={false}>
      <div className="">
        <p className="text-xl font-semibold text-primary-500  absolute top-4">
          Edit Employee
        </p>
        <Formik
          initialValues={{
            employeeName:selectedUser?.fullname ?? "",
            employeeEmail:selectedUser?.email ?? "",
            designationId: selectedUser?.designationid ?? "",
            reportingManagerId: selectedUser?.reportingmanagerid ?? "",
            departmentId:selectedUser?.departmentid ?? ""
          }}
          validationSchema={employeeSchema}
          onSubmit={handlePost}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) =>{
           return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="flex gap-2 mt-4">
                <div className="flex-row gap-2 w-full ">
                  <div className="flex gap-2">
                    {/* Employee Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="employeeName"
                          className="text-sm font-medium text-gray-700"
                        >
                          Employee Name
                        </label>
                      </div>
                      <input
                        type="text"
                        name="employeeName"
                        value={values.employeeName}
                        className={`${
                          errors.employeeName
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.employeeName && touched.employeeName && (
                        <p className="text-red-500 text-xs">
                          {errors.employeeName?.toString()}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 mt-2">
                    {/* Email Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="employeeEmail"
                          className="text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                      </div>
                      <input
                        type="text"
                        name="employeeEmail"
                        value={values.employeeEmail}
                        className={`${
                          errors.employeeEmail
                            ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                            : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                        } rounded-md shadow-sm focus:outline-none w-full px-3 disabled:cursor-not-allowed py-2 border sm:text-sm`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.employeeEmail && touched.employeeEmail && (
                        <p className="text-red-500 text-xs">
                          {errors.employeeEmail?.toString()}
                        </p>
                      )}
                    </div>
                  </div> 
                  <div className="flex gap-2 mt-2">
                    {/* Designation Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="designationId"
                          className="text-sm font-medium text-gray-700"
                        >
                          Designation Name
                        </label>
                           <Select
                            name="designationId"
                            value={values.designationId}
                            onBlur={handleBlur}
                            className={`${
                              errors.designationId
                                ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                                : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                            } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                            onChange={handleChange} >
                              <option value="">--Select--</option>
                              {userContext.designations?.length > 0 &&
                                userContext.designations.map((val: any) => (
                                  <option key={val.designationid} value={val.designationid}>
                                    {val.designationname}
                                  </option>
                                ))}
                            </Select>
                            {errors.designationId && touched.designationId && (
                            <p className="text-red-500 text-xs">
                              {errors.designationId?.toString()}
                            </p>
                           )}
                      </div>
                    </div>
                  </div> 
                  <div className="flex gap-2 mt-2">
                    {/* Reporting manager Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="reportingManagerId"
                          className="text-sm font-medium text-gray-700"
                        >
                          Reporting Manager
                        </label>
                      </div>

                      <Select
                            name="reportingManagerId"
                            value={values.reportingManagerId}
                            onBlur={handleBlur}
                            className={`${
                              errors.reportingManagerId
                                ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                                : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                            } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                            onChange={handleChange} >
                              <option value="">--Select--</option>
                              {userContext.circleUsers?.length > 0 &&
                                userContext.circleUsers?.map((val: any) => (
                                  <option key={val.invited_user_id} value={val.invited_user_id}>
                                    {val.full_name}
                                  </option>
                                ))}
                            </Select>
                            {errors.reportingManagerId && touched.reportingManagerId && (
                            <p className="text-red-500 text-xs">
                              {errors.reportingManagerId?.toString()}
                            </p>
                           )}
                    </div>
                  </div> 

                  <div className="flex gap-2 mt-2">
                    {/*Department Name */}
                    <div className="w-full">
                      <div className="mb-2 block">
                        <label
                          htmlFor="departmentId"
                          className="text-sm font-medium text-gray-700"
                        >
                          Department Name
                        </label>
                      </div>
                      <Select
                            name="departmentId"
                            value={values.departmentId}
                            onBlur={handleBlur}
                            className={`${
                              errors.departmentId
                                ? " border-red-300 focus:ring-red-500 focus:border-red-500"
                                : " border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                            } mt-2 rounded-md shadow-sm focus:outline-none w-full disabled:cursor-not-allowed border  sm:text-sm`}
                            onChange={handleChange} >
                              <option value="">--Select--</option>
                              {userContext.departments?.length > 0 &&
                                userContext.departments.map((val: any) => (
                                  <option key={val.departmentid} value={val.departmentid}>
                                    {val.departmentname}
                                  </option>
                                ))}
                            </Select>
                            {errors.departmentId && touched.departmentId && (
                            <p className="text-red-500 text-xs">
                              {errors.departmentId?.toString()}
                            </p>
                           )}
                    </div>
                  </div> 
                  <div className="mt-4">
                    <Button
                      type="submit"
                         disabled={isSubmitting}
                      className=" p-1 bg-primary-500 hover:bg-primary-700 text-white"
                        isProcessing={isSubmitting}
                    > Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}}
        </Formik>
      </div>
    </RightDrawer>
  );
};
export default EditEmployee;
