import { createContext, useState } from "react";
import {mapAllUsers,mapCircleUsers,mapLoggedInUser} from "../components/Content/AdminConsole/utils/helper";
import { consumeAuthKey } from "../Utilities/consumeAuthKey";
import { getUserInvitations } from "../Utilities/getUserInvitations";
import {OneTimeAuthConsumeResponse,UserContext as UserContextInterface,} from "../interfaces";
import { getWorkspacesList } from "../Utilities/workspace";
import { getAllDepartments } from "../Utilities/AdminConsole/workspace.department";
import { getAllDesignations } from "../Utilities/AdminConsole/workspace.designations";
import { getEmployeeDetails } from "../Utilities/AdminConsole/workspace.employee.details";

const defaultProvider = {
    user: {
        result: "",
        msg: "",
        email: "",
        user_id: "",
        avatar_version: 0,
        is_admin: false,
        is_owner: false,
        is_guest: false,
        is_billing_admin: false,
        role: "",
        is_bot: false,
        full_name: "",
        timezone: "",
        is_active: false,
        date_joined: "",
        delivery_email: "",
        avatar_url: "",
        profile_data: {},
        max_message_id: 0,
        workspace_id: "",
    },
    workspaces:[],
    users: [],
    circleUsers:[],
    config: () => Promise.resolve(),
    fetchUsers: () => {},
    fetchWorkspaces:()=>{},
    fetchDepartments:()=>{},
    departments:[],
    fetchUserGroups: () => {},
    userGroups: null,
    setUserGroups: () => {},
    designations:[],
    fetchDesignations:()=>{},
    employeeList:[],
    fetchEmployeeList:()=>{}
};

const UserContext = createContext<UserContextInterface>(defaultProvider);

function UserProvider({ children }: any) {
    const [user, setUser] = useState<any>();
    const [users, setUsers] = useState<any>([]);
    const [workspaces, setWorkspaces] = useState<any>([]);
    const [userGroups, setUserGroups] = useState<any>([]);
    const [departments,setDepartments] = useState<any>([]);
    const [designations,setDesignations] = useState<any>([]);
    const [employeeList,setEmployeeList] = useState<any>([]);
    const [circleUsers,setCircleUsers] = useState<any>([]);

    function useQuery() {
        return new URLSearchParams(document.location.search);
    }

    const query = useQuery();

    const setupZulip = async () => {
        // Check if the user is logged in by ensuring the user object is set
        // and the localStorage has all the necessary items
        if (
            user &&
            localStorage.getItem("accessToken") &&
            localStorage.getItem("workspaceId") &&
            localStorage.getItem("workspaceName") &&
            localStorage.getItem("email") &&
            localStorage.getItem("workspaceRole")
        ) {
            return true;
        }

        const oneTimeAuthKey = query.get("oneTimeAuthKey");
        let subdomain = window.location.host.split(".")[0];
        let loggedInUser = null;

        if (oneTimeAuthKey) {
            const data: OneTimeAuthConsumeResponse = await consumeAuthKey(
                oneTimeAuthKey
            );

            if (!data) {
                console.log("no consumeAuthKey data");
                return false;
            } else {
                console.log("consumeAuthKey data: ", data);
                loggedInUser = mapLoggedInUser(data);
                console.log("loggedInUser data: ", loggedInUser);
                setUser(loggedInUser);
            }

            const email = data.email;
            const token = data.token;
            subdomain = data.workspaceSubdomain;
            localStorage.setItem("inviteToken", data.token as string);
            localStorage.setItem("workspaceId", data.workspaceId as string);
            localStorage.setItem("workspaceName", data.workspaceName as string);
            localStorage.setItem("workspaceRole", data.workspaceRole as string);

            localStorage.setItem("email", email as string);
            localStorage.setItem("accessToken", token as string);
            if (loggedInUser?.is_admin || loggedInUser?.is_owner) {
                fetchUsers();
            }
            return true;
        }
        return false;
    };

    const fetchUsers = async () => {
        try {
            const allUsers = await getUserInvitations();
            const circleUsers = mapCircleUsers(allUsers.circleUsers)?.filter((val:any)=>val.is_active === true);
            setCircleUsers([...circleUsers]);
            const users = mapAllUsers(allUsers);
            console.log("🚀 ~ fetchUsers ~ users:", users);

            setUsers(
                users.map((user: any, index: number) => {
                    user.id = index + 1;
                    return user;
                })
            );
        } catch (error) {
            console.log("🚀 ~ fetchUsers ~ error:", error);
        }
    };

    const fetchWorkspaces = async () => {
        try {
            const response = await getWorkspacesList();
            console.log("🚀 ~ fetchWorkspaces ~ :", response);
            setWorkspaces(response);
        } catch (error) {
            console.log("🚀 ~ fetchWorkspaces ~ error:", error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await getAllDepartments();
            setDepartments(response);
        } catch (error) {
            console.log("🚀 ~ Departments ~ error:", error);
        }
    };

    const fetchDesignations = async () => {
        try {
            const response = await getAllDesignations();
            setDesignations(response);
        } catch (error) {
            console.log("🚀 ~ Designations ~ error:", error);
        }
    };

    const fetchEmployeeList = async () => {
        try {
            const response = await getEmployeeDetails();
            console.log("🚀 ~ Employee List :",response);
            setEmployeeList(response);
        } catch (error) {
            console.log("🚀 ~ Employee ~ error:", error);
        }
    };
    
    const fetchUserGroups = async (zulipClient: any = null) => {
        if (!zulipClient) return [];
        try {
            const response = await zulipClient.users.groups.retrieve();
            console.log(
                "user group",
                response.user_groups.filter(
                    (group: any) => !group.is_system_group
                )
            );
            setUserGroups(
                response.user_groups.filter(
                    (group: any) => !group.is_system_group
                )
            );
        } catch (error) {
            console.log("🚀 ~ fetchUserGroups ~ error:", error);
        }
    };

    const values = {
        user,
        users,
        circleUsers,
        workspaces,
        config: setupZulip,
        fetchUsers,
        fetchUserGroups,
        fetchWorkspaces,
        fetchDepartments,
        userGroups,
        setUserGroups,
        departments,
        fetchDesignations,
        designations,
        employeeList,
        fetchEmployeeList
    };

    return (
        <UserContext.Provider value={values}>{children}</UserContext.Provider>
    );
}

export { UserContext, UserProvider };










