import {  Dropdown } from "flowbite-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {defaultColDef} from "../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import EditDepartment from "../Modals/Departments/EditDepartment";
import { UserContext } from "../../../../context/UserContext";

interface DepartmentListProps {
  tableHeight: number;
  searchText?: string;
  users: any[];
  statusValue?: any;
  onConfirmModal: (response: any) => void;
}

const Departments: FC<DepartmentListProps> = ({
  tableHeight,
  searchText,
  users,
  onConfirmModal
}) => {

  const userContext = useContext(UserContext);
  const tableRef = useRef<AgGridReact>(null);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openEditDepartmentModal, setOpenEditDepartmentModal] = useState(false);
  const toggleEditDepartmentModal = () => setOpenEditDepartmentModal((old) => !old);
  const onClickEditLocationicon = (user: any) => {
    setSelectedUser(user);
    toggleEditDepartmentModal();
  };

  const onCheckboxChange = () => {
    console.log("selected");
  };
  
  useEffect(() => {
    tableRef?.current?.api?.redrawRows({ rowNodes: userContext.departments });
  }, [userContext.departments]);

  const getRowId = (params: any) => {
    return params.data.id || `${params.data.departmentname}_${params.data.departmentid}`;
  };

  const onClicDeleteDepartment = (user: any) => {
    onConfirmModal({user,message:`Are You Confirm for Delete <strong>${user.departmentname}</strong> Department`});
  };

  const parentDepartment = (id:string)=>{
    const parent = users.find((val)=>val.departmentid === id)
    return parent.departmentname
  }
  const columnDefs: any = [
    {
      field: "departmentname",
      headerName: "Department Name",
      cellRenderer: (params: any) => (
        <div className="flex items-center pt-2">
          <div className="flex flex-row h-full text-sm font-semibold items-center">
            <div style={{ maxWidth: 220, overflow: "hidden" }}>
              {params?.data?.departmentname}
            </div>
          </div>
          <div className="ml-auto">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <span>
                  <HiDotsVertical />
                </span>
              }
            >
                <Dropdown.Item
                  onClick={() => onClickEditLocationicon(params?.data)}
                >
                  Edit Department
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => onClicDeleteDepartment(params?.data)}
                >
                   Delete Department
                </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      ),
      cellStyle: { border: "unset", position: "static" },
      minWidth: 350,
      unSortIcon: true,
      pinned: true,
    },
    {
      field: "employeecount",
      headerName: "User Count",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data.employeecount}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    },
    {
      field: "parentdepartmentid",
      headerName: "Parent Department",
      cellRenderer: (params: any) => (
        <div className="mt-4 text-sm text-gray-500">
          {params?.data?.parentdepartmentid && parentDepartment(params?.data?.parentdepartmentid)}
        </div>
      ),
      minWidth: 80,
      cellStyle: { border: "unset" },
      unSortIcon: true,
    }
  ];

  const noRowsComponent = () => {
    return (
      <div className="ag-overlay-no-rows-wraper">
        <div className="text-2xl text-gray-400">Nothing to display!</div>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: tableHeight - 12 }}>
      <AgGridReact
        ref={tableRef}
        rowData={users}
        getRowId={getRowId}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onSelectionChanged={onCheckboxChange}
        noRowsOverlayComponent={noRowsComponent}
        quickFilterText={searchText}
        getRowStyle={() => ({
          background: "transparent",
          transform: "none",
          position: "relative",
        })}
        rowHeight={50}
        pagination={true}
        paginationPageSizeSelector={[10, 20, 50, 100]}
        paginationPageSize={10}
      />
      {openEditDepartmentModal && 
      <EditDepartment
        open={openEditDepartmentModal}
        toggleModal={toggleEditDepartmentModal}
        selectedUser={selectedUser}
      />}
    </div>
  );
};

export default Departments;
