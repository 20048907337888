import React, { FC } from "react";
import { LuRefreshCcw } from "react-icons/lu";
import { Button, Tooltip } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";

interface TableOptionsProps {
    tittle:string
    subtittle:string
    usersCount: number;
    fetchData: () => void;
    buttonText:string;
    addData:()=>void;
    isCreate?:boolean;
}

const TableHeader: FC<TableOptionsProps> = ({
    tittle,
    subtittle,
    usersCount,
    fetchData,
    addData,
    buttonText,
    isCreate=true
}) => {

    return (
        <>
            <div className="flex items-center w-full mb-4">
                <div className="text-2xl text-primary-500 font-bold">{tittle}</div>
                {isCreate &&
                <div className="ml-auto">
                    <Button
                        size="sm"
                        className="bg-primary-500 hover:bg-primary-700"
                        onClick={addData}>
                        <HiOutlinePlus className="mr-1 h-5 w-5" />
                        {buttonText}
                    </Button>
                </div>
                }
            </div>
            {usersCount > 0 && (
                <div className="flex items-center w-full bg-primary-100/50 rounded p-2 mb-2 shadow shadow-gray-300">
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex flex-row gap-2 items-center">
                            <p className="text-xs font-semibold text-primary-500 px-2 py-1 bg-primary-100 rounded-lg">
                             {subtittle}
                            </p>
                            {/* <Tooltip content="Refresh">
                                <LuRefreshCcw
                                    className="cursor-pointer text-primary-500"
                                    onClick={fetchData}
                                />
                            </Tooltip> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TableHeader;

