/* eslint-disable jsx-a11y/anchor-is-valid */
import { Sidebar as FlowbitSidebar, Tooltip } from "flowbite-react";
import type { FC } from "react";
import {  useState } from "react";
import {  HiUserGroup } from "react-icons/hi";
import { BsPersonWorkspace } from "react-icons/bs";
import { GrContactInfo } from "react-icons/gr";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaIdCard, FaBuilding } from "react-icons/fa";
import { PiUserListFill } from "react-icons/pi";
import { ImProfile } from "react-icons/im";
import { HiUsers } from "react-icons/hi";
import {  useNavigate } from "react-router-dom";

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const [openItem, setOpenItem] = useState<string | null>(null);

  const handleMenuClick = (path:string) => {
    navigate(path);
    setCurrentPage(path);
  };

  const handleCollapseClick = (item: string) => {
    setOpenItem(openItem === item ? null : item);
  };

  return (
    <div className="h-full " style={{ backgroundColor: "white" }}>
      <FlowbitSidebar aria-label="Sidebar" style={{ backgroundColor: "white" }}>
        <div className="flex h-full flex-col justify-between py-2">
          <FlowbitSidebar.Items>
            <FlowbitSidebar.ItemGroup>
              <FlowbitSidebar.Collapse
                icon={BsPersonWorkspace}
                label="Workspace"
                open={true}
                onClick={() => handleCollapseClick("workspace")}
              >
                <FlowbitSidebar.Item
                  className={
                    "/admin/workspaces/basic-details" === currentPage &&
                    "" === window.location.search
                      ? "bg-primary-100 shadow cursor-pointer"
                      : "cursor-pointer"
                  }
                  icon={GrContactInfo}
                  onClick={()=>handleMenuClick('/admin/workspaces/basic-details')}
                >
                  Basic Details
                </FlowbitSidebar.Item>
                <FlowbitSidebar.Item 
                 className={
                  "/admin/workspaces/locations" === currentPage &&
                  "" === window.location.search
                    ? "bg-primary-100 shadow cursor-pointer"
                    : "cursor-pointer"
                }
                icon={FaMapLocationDot}
                onClick={()=>handleMenuClick('/admin/workspaces/locations')}
                >
                  Locations
                </FlowbitSidebar.Item>
                <FlowbitSidebar.Item icon={FaBuilding}  className={
                  "/admin/workspaces/departments" === currentPage &&
                  "" === window.location.search
                    ? "bg-primary-100 shadow cursor-pointer"
                    : "cursor-pointer"
                }
                onClick={()=>handleMenuClick('/admin/workspaces/departments')}
                >
                  Departments
                </FlowbitSidebar.Item>
                <FlowbitSidebar.Item icon={FaIdCard}
                className={
                  "/admin/workspaces/designations" === currentPage &&
                  "" === window.location.search
                    ? "bg-primary-100 shadow cursor-pointer"
                    : "cursor-pointer"
                }
                onClick={()=>handleMenuClick('/admin/workspaces/designations')}
                >
                  Designation
                </FlowbitSidebar.Item>
              </FlowbitSidebar.Collapse>
              <FlowbitSidebar.Collapse
                icon={HiUsers}
                label="Users"
                open={true}
                onClick={() => handleCollapseClick("users")}
              >
                <FlowbitSidebar.Item
                  icon={PiUserListFill}
                  className={
                    "/admin/users-list" === currentPage &&
                    "" === window.location.search
                      ? "bg-primary-100 shadow cursor-pointer"
                      : "cursor-pointer"
                  }
                  onClick={() => handleMenuClick("/admin/users-list")}
                >
                  Users List
                </FlowbitSidebar.Item>
                <FlowbitSidebar.Item
                  icon={ImProfile}
                  className={
                    "/admin/employee" === currentPage &&
                    "" === window.location.search
                      ? "bg-primary-100 shadow cursor-pointer"
                      : "cursor-pointer"
                  }
                  onClick={() => handleMenuClick("/admin/employee")}
                >
                  Employee Details
                </FlowbitSidebar.Item>
              </FlowbitSidebar.Collapse>
              <FlowbitSidebar.Item
                href="#"
                icon={HiUserGroup}
                className={
                  "/admin/groups" === currentPage &&
                  "" === window.location.search
                    ? "bg-primary-100 shadow"
                    : ""
                }
              >
                <Tooltip content="Coming soon">
                  <span className="cursor-not-allowed opacity-50">Groups</span>
                </Tooltip>
              </FlowbitSidebar.Item>
            </FlowbitSidebar.ItemGroup>
          </FlowbitSidebar.Items>
        </div>
      </FlowbitSidebar>
    </div>
  );
};

export default Sidebar;
